import React from "react";
import {Button, Form, Modal} from "semantic-ui-react";
import {formatOfferElement, Formats, split} from "../../utils";
import {types} from "../../store/types";
import {FaPlus} from "react-icons/fa";
import {FaTrash} from "react-icons/all";

interface ConstructorRulesProps {
  xpath: string;
  format: Formats;
  action: string;
  value: string;

  updateRule(xpath: string, action: string, value: string): any;
}

const compares = [
  {text: '=', value: '='},
  {text: '!=', value: '!='},
  {text: '<', value: '<'},
  {text: '<=', value: '<='},
  {text: '>', value: '>'},
  {text: '>=', value: '>='},
]

const dividers = [
  {text: 'или', value: 'or'},
  {text: 'и', value: 'and'}
]


interface Condition {
  property: string;
  value: string;
  compare: string;
  nextDivider: string | null;
}

type ConditionGroupRaw = (string | ConditionGroupRaw)[]


interface ConstructorRulesState {
  conditions: Condition[];
  path: string;
  action: string;
  value: string;
  showModal: boolean;
}

function parseProperty(property: string, format: Formats): string {
  if (format === 'yandex') {
    return property.replace('xmlns:', '');
  } else {
    return property
  }
}

function parseConditions(conditionsString: string, format: Formats = 'yandex'): Condition[] {
  let conditions: Condition[] = []
  let regexp = /(([@:\w\d-]+)(=|!=|<|<=|>|>=)"([^"]*)"\s*(or|and)*\s*)/g
  let res = conditionsString.matchAll(regexp) as unknown as string[]
  for (const match of res) {
    conditions.push({
      property: parseProperty(match[2], format),
      compare: match[3],
      value: match[4],
      nextDivider: match[5] ? match[5] : null
    })
  }
  return conditions
}

function renderProperty(property: string, format: Formats): string {
  if (format === 'yandex') {
    return 'xmlns:' + property;
  } else {
    return property;
  }
}

function renderConditions(conditions: Condition[], format: Formats = 'yandex'): string {
  let conditionsStrings = [];
  for (const condition of conditions) {
    let conditionString = '';
    conditionString += renderProperty(condition.property, format) + condition.compare + '"' + condition.value + '"';
    if (condition.nextDivider) conditionString += " " + condition.nextDivider;
    conditionsStrings.push(conditionString)
  }

  return conditionsStrings.join(' ');
}

export class ConstructorRules extends React.Component<ConstructorRulesProps, ConstructorRulesState> {
  constructor(props: ConstructorRulesProps) {
    super(props);
    this.state = {
      conditions: parseConditions(props.xpath, props.format),
      action: props.action,
      value: props.value,
      path: props.xpath.includes('/') ? split(props.xpath, /\//g, 1)[1] : props.xpath,
      showModal: false
    };
    this.updateRule = this.updateRule.bind(this);
    this.addCondition = this.addCondition.bind(this);
    this.updateCondition = this.updateCondition.bind(this);
    this.deleteCondition = this.deleteCondition.bind(this);
    this.dismissSaving = this.dismissSaving.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<ConstructorRulesProps>, prevState: Readonly<ConstructorRulesState>, snapshot?: any): void {
    if (
      prevProps.action !== this.props.action ||
      prevProps.value !== this.props.value ||
      prevProps.xpath !== this.props.xpath
    ) {
      this.setState({
        conditions: parseConditions(this.props.xpath, this.props.format),
        action: this.props.action,
        value: this.props.value,
        path: this.props.xpath.includes('/') ? split(this.props.xpath, /\//g, 1)[1] : this.props.xpath,
      })
    }
  }

  updateRule() {
    let xpath = `${formatOfferElement[this.props.format]}`
    if (this.state.conditions.length !== 0)
      xpath += `[${renderConditions(this.state.conditions, this.props.format)}]`
    xpath += `/${this.state.path}`
    this.props.updateRule(xpath, this.state.action, this.state.value)
    this.setState({showModal: false});
  }

  addCondition() {
    let newArray = [...this.state.conditions, {
      property: '',
      value: '',
      compare: '=',
      nextDivider: null
    }]
    if (newArray.length > 1)
      newArray[newArray.length - 2].nextDivider = 'or';
    // console.log()
    this.setState({conditions: newArray})
  }

  updateCondition(i: number, prop: 'property' | 'nextDivider' | 'value' | 'compare', value: string | null) {
    let arr = [...this.state.conditions];
    if (prop === 'nextDivider')
      arr[i][prop] = value;
    else
      arr[i][prop] = value ? value.toString() : ''
    this.setState({conditions: arr});
  }

  deleteCondition(i: number) {
    let arr = [...this.state.conditions];

    arr.splice(i, 1);

    if (i === arr.length)
      arr[arr.length - 1].nextDivider = null

    this.setState({conditions: arr});
  }

  dismissSaving(needConfirm: boolean = false) {
    if (needConfirm) {
      // eslint-disable-next-line no-restricted-globals
      if (!confirm('Потерять изменения?')) {
        return;
      }
    }
    this.setState({
      conditions: parseConditions(this.props.xpath, this.props.format),
      action: this.props.action,
      value: this.props.value,
      path: this.props.xpath.includes('/') ? split(this.props.xpath, /\//g, 1)[1] : this.props.xpath,
      showModal: false
    })
  }

  render() {
    return <Modal
      open={this.state.showModal}
      onClose={() => this.dismissSaving(true)}
      onOpen={() => this.setState({showModal: true})}
      trigger={<Button color='blue'>Конструктор</Button>}
      style={{
        // left: '50%',
        top: '50%',
        position: 'absolute',
        height: '90%',
        transform: 'translateY(-50%)'
      }}
      size={'fullscreen'}
    >
      <Modal.Header closeButton>Конструктор правил</Modal.Header>
      <Modal.Content scrolling style={{height: '100%'}}>
        <Form>
          <Form.Group widths={'equal'} inline>
            <Form.Input
              fluid
              value={this.state.path}
              onChange={(e, data) => this.setState({path: data.value})}
            />
            <Form.Select options={types} value={this.state.action} fluid
                         onChange={(e, data) =>
                           this.setState({action: data.value ? data.value.toString() : 'add'})}
            />
            <Form.Input fluid
                        value={this.state.value}
                        onChange={(e, data) =>
                          this.setState({value: data.value})}
            />
          </Form.Group>
          {this.state.conditions.map((c, i) => <Form.Group key={i} widths={'equal'} inline>
            <Form.Input fluid placeholder="Свойство" value={c.property}
                        onChange={(e, d) => this.updateCondition(i, 'property', d.value)}/>
            <Form.Select fluid options={compares} value={c.compare}
                         onChange={(e, d) => this.updateCondition(i, 'compare', d.value ? d.value.toString() : '=')}/>
            <Form.Input fluid placeholder="Значение" value={c.value}
                        onChange={(e, d) => this.updateCondition(i, 'value', d.value)}/>
            <Form.Select options={dividers} fluid value={c.nextDivider ? c.nextDivider : undefined}
                         disabled={i === this.state.conditions.length - 1}
                         onChange={(e, d) => this.updateCondition(i, 'nextDivider', d.value ? d.value.toString() : null)}/>
            <Button color='red' onClick={() => this.deleteCondition(i)}><FaTrash/></Button>
          </Form.Group>)}
          <Button color='green' onClick={() => this.addCondition()}><FaPlus className='mr-2'/>Добавить</Button>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        {/* eslint-disable-next-line no-restricted-globals */}
        <Button color="red" onClick={() => this.dismissSaving(true)}>Отмена</Button>
        <Button color="green" onClick={() => this.updateRule()}>Сохранить</Button>
      </Modal.Actions>
    </Modal>
  }
}
