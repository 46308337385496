import axios from "axios";
import config from "../config";
import {ApiResponse} from "./types";
import {ProjectStatic} from "../store/types";

export async function setStatic(projectId: string, xml: string | null) {
  try {
    return (await axios.post(config.api.baseUrl + '/project/' + projectId + '/static', {xml})).data;
  } catch (e) {
    return e.response.data;
  }
}

export async function getStatic(projectId: string): Promise<ApiResponse<ProjectStatic | null>> {
  try {
    return (await axios.get(config.api.baseUrl + '/project/' + projectId + '/static')).data;
  } catch (e) {
    return e.response.data;
  }
}
