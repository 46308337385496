import React from "react";
import {AppStateRedux, Group, User} from "../../store/types";
import {compose, Dispatch} from "redux";
import {connect} from "react-redux";
import {userLogin, userSet} from "../../store/user/actions";
import {addToast} from "../../store/toast/actions";
import {groupsSet, toggleAddProjectModal} from "../../store/project/actions";
import {FormComponent} from "../Field/Field";
import {Button, Table} from "semantic-ui-react";
import {createGroup, deleteGroup} from "../../api/groups";
import {LoadingSpinner} from "../Spinner/Spinner";

interface GroupsComponentProps {
	groups: Group[];
	setGroups: (groups: Group[]) => void
}

interface GroupsComponentState {
	title: string;
	loading: boolean;
}

class GroupsComponent extends React.Component<GroupsComponentProps, GroupsComponentState> {
	constructor(props: GroupsComponentProps) {
		super(props);

		this.state = {
			title: '',
			loading: false
		}
	}

	async deleteGroup(groupId: string, index: number){
		this.setState({loading: true});
		let res = await deleteGroup(groupId);
		if (!res.ok) return;
		let tempArr = this.props.groups.slice(0);
		tempArr.splice(index, 1);

		this.props.setGroups(tempArr);

		this.setState({loading: false})
	}

	render() {
		if (this.state.loading) return <LoadingSpinner />
		return <div className='m-3 p-3'>
			<div className='row'>
				<div className='col-9'>
					<FormComponent value={this.state.title} onChange={(value => this.setState({title: value}))}/>
				</div>
				<div className='col-3'>
					<Button color='green' onClick={_ => this.createGroup()}>Создать группу</Button>
				</div>
			</div>

			<Table>
				<thead>
				<th>ID</th>
				<th>Название</th>
				<th>Кол-во проектов</th>
				<th/>
				</thead>
				<tbody>
				{this.props.groups.length === 0 ? <p>Нет групп. Создайте новую, используя форму выше.</p> : null}
				{this.props.groups.map((g, i) => <tr>
					<td>{g.id.substr(0,6)}</td>
					<td>{g.title}</td>
					<td>{g.projectsCount}</td>
					<td><Button color={'red'} onClick={_ => this.deleteGroup(g.id, i)}>Удалить группу</Button></td>
				</tr>)}

				</tbody>
			</Table>
		</div>
	}

	async createGroup() {
		this.setState({loading: true});

		let res = await createGroup(this.state.title);
		if (!res.ok) return;
		this.props.setGroups([...this.props.groups, {
			id: res.result.id,
			projectsCount: res.result.projectsCount,
			title: res.result.title
		}])

		this.setState({loading: false});
	}
}

const mapStateToProps = (state: AppStateRedux) => {
	return {
		groups: state.projects.groups
	}
}

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		setGroups: (groups: Group[]) => dispatch(groupsSet(groups))
	}
}
export const GroupsList = compose(connect(mapStateToProps, mapDispatchToProps))(GroupsComponent)
