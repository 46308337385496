import {withRouter} from "react-router-dom";
import {compose, Dispatch} from "redux";
import {connect} from "react-redux";
import {AppStateRedux, Project, ProjectState} from "../../store/types";
import React from "react";
import {projectsSet} from "../../store/project/actions";
import {deleteProject, duplicateProject, getAllProjects} from "../../api/project";
import {addToast} from "../../store/toast/actions";
import errors from "../../api/errors";
import {Button, ButtonGroup, Spinner, Table} from "react-bootstrap";
import {FaCopy, FaEye, FaTrash} from "react-icons/all";
import {formats} from "../../utils";

interface ProjectsListProps {
	projects: ProjectState;

	projectsSet(projects: Project[], count: number): void;

	addToast(body: string, header?: string): void;

	history: any;
	match: any;
	location: any;
}

interface HiddenGroupMap {
	[id: string]: boolean
}

interface ProjectsListState {
	loading: boolean;
	hiddenGroupMap: HiddenGroupMap
}

class ProjectsListComponent extends React.Component<ProjectsListProps, ProjectsListState> {
	constructor(props: ProjectsListProps) {
		super(props);
		this.state = {loading: false, hiddenGroupMap: {}}
		this.defaultHiddenGroupMap = this.defaultHiddenGroupMap.bind(this);
		this.toggleGroupHidden = this.toggleGroupHidden.bind(this);
	}

	componentDidMount(): void {
		this.updateProjects();
	}

	defaultHiddenGroupMap() {
		let newMap: HiddenGroupMap = {};
		Object.keys(this.props.projects.groupsMap).forEach(id => {
			newMap[id] = true
		})
		this.setState({hiddenGroupMap: newMap});
	}

	toggleGroupHidden(id: string) {
		this.setState({hiddenGroupMap: Object.assign({}, this.state.hiddenGroupMap, {[id]: !this.state.hiddenGroupMap[id]})});
	}

	async updateProjects(): Promise<void> {
		this.setState({loading: true})
		let res = await getAllProjects();
		if (!res.ok)
			return this.props.addToast(errors(res.code));

		this.props.projectsSet(res.result.projects, res.result.count);
		this.defaultHiddenGroupMap();
		this.setState({loading: false})
	}

	render(): React.ReactNode {
		if (this.state.loading) {
			return (<p>Загрузка проектов...<Spinner animation='grow' variant='dark'/></p>)
		}
		return (this.props.projects.count !== 0 ? <div>{
			Object.keys(this.props.projects.groupsMap).map(id =>
				<div style={{width: '100%'}}>
					<h5
						style={{
							paddingTop: '5px',
							paddingBottom: '5px'
						}}
						onClick={_ => this.toggleGroupHidden(id)}>{this.props.projects.groupsMap[id].title} ({this.props.projects.groupsMap[id].projectsCount})</h5>
					<div hidden={this.state.hiddenGroupMap[id]}>
						{this.props.projects.projectsByGroup[id].length === 0 ?
							<p style={{textAlign: 'center'}}>В данной группе нет проектов</p> :
						<Table striped bordered hover>
							<thead>
							<tr>
								<th>ID</th>
								<th>Название</th>
								<th>Формат</th>
								<th>Действия</th>
							</tr>
							</thead>
							<tbody>
							{this.props.projects.projectsByGroup[id].map(project =>
								<tr hidden={this.state.hiddenGroupMap[id]} style={{alignItems: 'baseline'}}>
									<td style={{width: '8em', textAlign: 'center'}}>
										<Button variant="link" onClick={() => this.props.history.push('/project/' + project.id)}>
											<span className="text-monospace">{project.id.substr(0, 6)}</span>
										</Button>
									</td>
									<td><p>{project.title}</p></td>
									<td style={{width: '8em', textAlign: 'center'}}><p>{formats[project.settings.format]}</p></td>
									<td><ButtonGroup>
										<Button variant="primary"
										        onClick={() => this.props.history.push('/project/' + project.id)}><FaEye/></Button>
										<Button variant="success" onClick={() => duplicateProject(project.id)}><FaCopy/></Button>
										<Button variant="danger"
										        onClick={() => window.confirm('Удалить проект? ' + '(' + project.title + ')') ? deleteProject(project.id) : null}><FaTrash/></Button>
									</ButtonGroup></td>
								</tr>
							)}
							</tbody>
						</Table>}
					</div>

				</div>
			)}</div> : <h5>Нет проектов</h5>)
	}
}

function mapStateToProps(state: AppStateRedux) {
	return {
		projects: state.projects
	}
}

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		projectsSet: (projects: Project[], count: number) => dispatch(projectsSet(projects, count)),
		addToast: (body: string, header?: string) => dispatch(addToast(body, header))
	}
}


export const ProjectsList = withRouter(compose(connect(mapStateToProps,
		mapDispatchToProps))(ProjectsListComponent))
