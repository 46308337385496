import {Action, ToastState} from "../types";
import {HIDE_TOAST, ADD_TOAST} from "./actions";


function add(state: ToastState[], body: string, header?: string): ToastState[] {
  return [...state, {body, header}];
}

function hide(state: ToastState[], index: number): ToastState[] {
  return state.filter((_, i) => i !== index)
}

export function toastReducer(state: ToastState[] = [], action: Action): ToastState[] {
  switch (action.type) {
    case HIDE_TOAST:
      return hide(state, action.index);
    case ADD_TOAST:
      return add(state, action.body, action.header);
    default:
      return state;
  }
}
