import React from "react";
import {AppStateRedux, Mirror} from "../../store/types";
import {Col, Row} from "react-bootstrap";
import {Button, Form} from "semantic-ui-react";
import {createMirror, deleteMirror, getMirrors} from "../../api/mirror";
import {compose, Dispatch} from "redux";
import {connect} from "react-redux";
import {addToast} from "../../store/toast/actions";
import {LoadingSpinner} from "../Spinner/Spinner";
import {FaPlus, FaTrash} from "react-icons/all";
import config from "../../config";

interface ProjectMirrorsState {
  newMirrorHash: string;
  loading: boolean
}

interface ProjectMirrorsProps {
  mirrors: Mirror[];
  projectId: string;

  updateMirrors(newMirrors: Mirror[]): any;

  addToast(body: string, header?: string): any;
}

class ProjectMirrorsComponent extends React.Component<ProjectMirrorsProps, ProjectMirrorsState> {
  constructor(props: ProjectMirrorsProps) {
    super(props);

    this.state = {
      newMirrorHash: '',
      loading: false
    }
    this.updateMirrors = this.updateMirrors.bind(this)
    this.createMirror = this.createMirror.bind(this)
  }

  async updateMirrors() {
    let res = await getMirrors(this.props.projectId);
    if (res.ok) this.props.updateMirrors(res.result.mirrors)
  }

  async deleteMirror(mirrorId: string) {
    let res = await deleteMirror(this.props.projectId, mirrorId);
    if (!res.ok) this.props.addToast(res.msg, 'Ошибка')
    if (res.ok) await this.updateMirrors()
  }

  async createMirror() {
    let res = await createMirror(this.props.projectId, this.state.newMirrorHash);
    if (!res.ok) this.props.addToast(res.msg, 'Ошибка')
    if (res.ok) await this.updateMirrors()
  }

  handleOnChangeForm(newVal: string) {
    this.setState({newMirrorHash: newVal});
  }

  render(): React.ReactNode {
    return <div className='m-3 p-3'>
      <Form loading={this.state.loading}>
        <Form.Group inline>
          <Form.Input
            onChange={(e, data) => this.handleOnChangeForm(data.value)}
            placeholder="Создать новое зеркало"
          />
          <Button content={<span><FaPlus className='mr-2'/>Добавить</span>} onClick={() => this.createMirror()}/>
        </Form.Group>
        {this.props.mirrors.length === 0 ? <p>Нет зеркал.</p> : null}
        {this.props.mirrors.map(m => <Form.Group className='my-2 align-items-center' inline>
          <a href={config.xmlUrl + '/' + m.hash + '.xml'} target='_blank' className='mr-5'>{config.xmlUrl + '/' + m.hash + '.xml'}</a>
          <Button color="red" content={<span><FaTrash className='mr-2'/>Удалить</span>}
                           onClick={() => this.deleteMirror(m.id)} size={'tiny'}/>
        </Form.Group>)}
      </Form>
    </div>
  }
}

const mapStateToProps = (state: AppStateRedux) => {
  return {}
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    addToast: (body: string, header?: string) => dispatch(addToast(body, header))
  }
}

export const ProjectMirrors = compose(connect(mapStateToProps, mapDispatchToProps))(ProjectMirrorsComponent)
