import {ApiResponse} from "./types";
import {Statistic} from "../store/types";
import axios from "axios";
import {url} from "./utils";

export async function getStatistics(field: string, value: string): Promise<ApiResponse<Array<Statistic>>> {
  try {
    return (await axios.get(url('/statistics'), {
      params: {
        field, value
      }
    })).data;
  } catch (e) {
    return e.response.data;
  }
}
