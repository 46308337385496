import React from "react";
import {compose, Dispatch} from "redux";
import {connect} from "react-redux";
import {AppStateRedux, Group, User, UserState} from "../../store/types";
import {withRouter} from "react-router";
// import {addToast} from "../../store/toast/actions";
import {userLogin, userSet} from "../../store/user/actions";
import {getMe} from "../../api/auth";
import {addToast} from "../../store/toast/actions";
import errors from "../../api/errors";
import {Button, Nav, Navbar, Tab, Tabs} from "react-bootstrap";
import {ProjectsList} from "../../components/ProjectsList/ProjectsList";
import {FaPlus} from "react-icons/all";
import {groupsSet, toggleAddProjectModal} from "../../store/project/actions";
import {GoogleConvertScreen} from "../../components/GoogleConvertScreen/GoogleConvertScreen";
import {XmlConvertScreen} from "../../components/XmlConvertScreen/XmlConvertScreen";
import {getUserGroups} from "../../api/groups";
import {GroupsList} from "../../components/Groups/Groups";

interface ProfilePageProps {
  user: UserState;
  match: any;
  location: any;
  history: any;

  login(): any;

  setUser(user: User): void;

  addToast(body: string, header?: string): void;

  toggleAddProjectModal(show: boolean): void;
}

interface ProfilePageState {
  currentTab: string | null
}

class ProfilePageComponent extends React.Component<ProfilePageProps, ProfilePageState> {
  constructor(props: ProfilePageProps) {
    super(props);

    this.state = {currentTab: 'projects'}
  }

  componentDidMount(): void {
    console.log('Profile page mounted', this.props.user)
    if (!this.props.user.isLogged) {
      this.props.history.push('/login');
      return;
    }
    this.updateUser()
  }

  selectTab(key: string | null) {
    this.setState(Object.assign({}, this.state, {currentTab: key}));
  }

  async updateUser(): Promise<void> {
    let res = await getMe();
    if (!res.ok) {
      this.props.history.push('/login')
      return this.props.addToast(errors(res.code))
    }
    this.props.setUser(res.result);
  }

  render(): React.ReactNode {
    return <div>
      <Navbar>
        <Navbar.Brand>XML-108</Navbar.Brand>
        <div style={{width: '100%', justifyContent: 'right', display: 'flex', alignItems: 'baseline', fontSize: '1.25em'}}>
          {this.props.user.user?.username}
          <Button variant="link">Выйти</Button>
        </div>
      </Navbar>
      <Tabs
        activeKey={this.state.currentTab}
        onSelect={(key: string | null) => this.selectTab(key)}
      >
        <Tab eventKey="projects" title="Проекты" >
          <div className="m-1">
            <Button variant="success" className="mb-2" onClick={() => this.props.toggleAddProjectModal(true)}><FaPlus/>Создать проект</Button>
            <ProjectsList/>
          </div>
        </Tab>
        <Tab eventKey="google" title="Конвертация Google">
          <GoogleConvertScreen/>
        </Tab>
        <Tab eventKey="convert" title="Конвертация XML">
          <XmlConvertScreen/>
        </Tab>
        <Tab eventKey="groups" title="Группы">
          <GroupsList/>
        </Tab>
      </Tabs>
    </div>
  }
}


const mapStateToProps = (state: AppStateRedux) => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    login: () => dispatch(userLogin()),
    setUser: (user: User) => dispatch(userSet(user)),
    addToast: (body: string, header?: string) => dispatch(addToast(body, header)),
    toggleAddProjectModal: (show: boolean) => dispatch(toggleAddProjectModal(show))
  }
}
export const ProfilePage = withRouter(compose(connect(mapStateToProps, mapDispatchToProps),)(ProfilePageComponent))
