import axios from "axios";
import {url} from "./utils";
import {ApiResponse, ApiResponsePagination} from "./types";
import {Group} from "../store/types";

export async function createGroup(title: string): Promise<ApiResponse<Group>>{
	try {
		return (await axios.post(url('/group'), {title})).data;
	} catch (e) {
		return e.response.data;
	}
}

export async function getUserGroups(): Promise<ApiResponsePagination<{groups: Group[]}>>{
	try {
		return (await axios.get(url('/groups'))).data;
	} catch (e) {
		return e.response.data;
	}
}

export async function addToGroup(groupId: string, projectId: string): Promise<ApiResponse<any>>{
	try {
		return (await axios.post(url('/group/', groupId), {projectId})).data;
	} catch (e) {
		return e.response.data;
	}
}

export async function deleteFromGroup(groupId: string, projectId: string): Promise<ApiResponse<any>>{
	try {
		return (await axios.delete(url('/group/', groupId, '/', projectId))).data;
	} catch (e) {
		return e.response.data;
	}
}
export async function deleteGroup(groupId: string): Promise<ApiResponse<any>>{
	try {
		return (await axios.delete(url('/group/', groupId))).data;
	} catch (e) {
		return e.response.data;
	}
}
