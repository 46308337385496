import {Rule} from "../store/types";
import axios from "axios";
import config from "../config";
import {ApiResponse} from "./types";
import {url} from "./utils";

interface RuleCreate {
  type: string;
  xpath: string;
  value: string;
  order: number;
  toEnd: boolean;
}

type RuleCreateResponse = Rule

export async function createRule(projectId: string, rule: RuleCreate): Promise<ApiResponse<RuleCreateResponse>> {
  try {
    return (await axios.post(config.api.baseUrl + '/project/' + projectId + '/rule', rule)).data;
  } catch (e) {
    return e.response.data;
  }
}

interface RuleEdit {
  type: string;
  xpath: string;
  value: string;
  order: number;
}

export async function editRule(projectId: string, ruleId: string, rule: Partial<RuleEdit>): Promise<ApiResponse<Rule>> {
  try {
    return (await axios.put(config.api.baseUrl + '/project/' + projectId + '/rule/' + ruleId, rule)).data;
  } catch (e) {
    return e.response.data;
  }
}

export async function editRuleOrder(projectId: string, ids: string[]) {
  try {
    return (await axios.post(config.api.baseUrl + '/project/' + projectId + '/editOrder', {order: ids})).data;
  } catch (e) {
    return e.response.data;
  }
}

export async function deleteRule(projectId: string, ruleId: string) {
  try {
    return (await axios.delete(config.api.baseUrl + '/project/' + projectId + '/rule/' + ruleId)).data;
  } catch (e) {
    return e.response.data;
  }
}

export async function getRules(projectId: string): Promise<ApiResponse<Rule[]>>{
  try {
    return (await axios.get(url('/project/', projectId, '/rules'))).data;
  } catch (e) {
    return e.response.data;
  }
}
