export function isXpathValid(xpath: string): boolean {
  try {
    document.evaluate(xpath.replace(/xmlns:/g, ''), document, null, XPathResult.ANY_TYPE, null)
    return true;
  } catch (e) {
    console.warn(e);
    return false;
  }
}

export function array_move(arr: any[], old_index: number, new_index: number) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}

export const formats = {
  yandex: 'Яндекс',
  cian: 'ЦИАН',
  avito: 'Авито',
  yml: 'YML'
}

export type Formats = 'yandex' | 'cian' | 'avito' | 'yml'

export const formatOfferElement = {
  yandex: 'offer',
  cian: 'object',
  avito: 'Ad',
  yml: 'offer'
}

export const actions = {
  add: 'Добавить',
  edit: 'Изменить',
  append: 'Добавить в конец',
  delete: 'Удалить'
}

export const formatsSelect = Object.entries(formats).map(([key, value]) => {return {value: key, text: value}});

export function rsplit(str: string,sep: string, maxsplit: number = 0): string[]{
  let split = str.split(sep);
  return maxsplit ? [ split.slice(0, -maxsplit).join(sep) ].concat(split.slice(-maxsplit)) : split;
}

export function split(str: string, sep: RegExp, n: number): string[] {
  let out = [];

  while(n--) { // @ts-ignore
    out.push(str.slice(sep.lastIndex, sep.exec(str).index));
  }

  out.push(str.slice(sep.lastIndex));
  return out;
}

export function download(value:string, title:string, mime:string){
  console.log(value);
  const element = document.createElement("a");
  // @ts-ignore
  const file = new Blob([value], {type: mime});
  element.href = URL.createObjectURL(file);
  element.download = title;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
}
