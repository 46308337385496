import React from "react";
import {AppStateRedux, Group} from "../../store/types";
import {Button, Form, Table} from "semantic-ui-react";
import {FaPlus, FaTrash} from "react-icons/all";
import config from "../../config";
import {compose} from "redux";
import {connect} from "react-redux";
import {addToGroup, deleteFromGroup} from "../../api/groups";
import {Spinner} from "react-bootstrap";


interface ProjectGroupsProps {
	selectedGroups: Group[];
	allGroups: Group[];
	projectId: string;
	updateGroups: (groups: Group[]) => any;
}

interface selectedMap {
	[id: string]: boolean;
}

interface ProjectGroupsState {
	selectedMap: selectedMap;
	loading: boolean;
}

class ProjectGroupsComponent extends React.Component<ProjectGroupsProps, ProjectGroupsState>{
	constructor(props: ProjectGroupsProps) {
		super(props);
		this.state = {
			loading: false,
			selectedMap: {}
		}
		this.updateSelectedMap = this.updateSelectedMap.bind(this)
		this.addToGroup = this.addToGroup.bind(this)
		this.deleteFromGroup = this.deleteFromGroup.bind(this)
	}

	componentDidMount() {
		this.updateSelectedMap();
	}

	updateSelectedMap(){
		let map: selectedMap = {}
		this.props.allGroups.forEach(g => {
			map[g.id] = false
		})
		this.props.selectedGroups.forEach(g => {
			map[g.id] = true
		});

		this.setState({selectedMap: map})
	}

	async addToGroup(groupId: string){
		this.setState({loading: true});
		let res = await addToGroup(groupId, this.props.projectId);
		if (!res.ok) return;

		this.setState({selectedMap: Object.assign({}, this.state.selectedMap, {[groupId]: true})});
		console.log(this.state)
		this.setState({loading: false});

	}

	async deleteFromGroup(groupId: string){
		this.setState({loading: true});
		let res = await deleteFromGroup(groupId, this.props.projectId);
		if (!res.ok) return;
		this.setState({selectedMap: Object.assign({}, this.state.selectedMap, {[groupId]: false})});
		this.setState({loading: false});
	}

	render() {
		return <div className='m-3 p-3'>
			{this.state.loading? <Spinner animation={'grow'}/>: null}
			<div hidden={this.state.loading}>
				<Table>
				<thead>
				<th>ID</th>
				<th>Название</th>
				<th/>
				</thead>
				<tbody>
				{this.props.allGroups.map(g => <tr>
					<td>{g.id.substr(0, 6)}</td>
					<td>{g.title}</td>
					<td>{this.state.selectedMap[g.id] ?
						<Button color="red" onClick={() => this.deleteFromGroup(g.id)}>Удалить из группы</Button> :
						<Button color="green" onClick={() => this.addToGroup(g.id)}>Добавить в группу</Button>
					}</td>
					</tr>
				)}
				</tbody>
			</Table>
			</div>
		</div>
	}
}

const mapStateToProps = (state: AppStateRedux) => {
	return {
		allGroups: state.projects.groups
	}
}

export const ProjectGroups = compose(connect(mapStateToProps))(ProjectGroupsComponent)
