import React from "react";
import {Rule as RuleType, types} from "../../store/types";
import {connect} from "react-redux";
import {compose, Dispatch} from "redux";
import {Form} from "semantic-ui-react";
import {Button, ButtonGroup, Spinner} from "react-bootstrap";
import {FaArrowDown, FaArrowUp, FaCheck} from "react-icons/fa";
import {FaTrash} from "react-icons/all";
import {Formats, isXpathValid} from "../../utils";
import {deleteRule, editRule} from "../../api/rule";
import {addToast} from "../../store/toast/actions";
import {FormComponent} from "../Field/Field";
import {ConstructorRules} from "../ConstructorRules/ConstructorRules";
import {StatisticsModal} from "../StatisticsModal/StatisticsModal";

interface RuleProps {
  order: number;
  rule: RuleType;
  isLast: boolean;
  isFirst: boolean;
  format: Formats;

  changeOrder(mode: 'up' | 'down'): void;

  updateProject(): void;

  addToast(body: string, header?: string): void;
}

interface RuleState {
  xpath: string;
  xpathValid: boolean;
  type: string;
  value: string;
  changed: boolean;
  loading: boolean;
}

class RuleComponent_ extends React.Component<RuleProps, RuleState> {
  constructor(props: RuleProps) {
    super(props);

    this.state = {
      xpath: this.props.rule.xpath,
      xpathValid: isXpathValid(this.props.rule.xpath),
      type: this.props.rule.type,
      value: this.props.rule.value,
      changed: false,
      loading: false
    }
    this.handleChange = this.handleChange.bind(this);
    this.updateRule = this.updateRule.bind(this);
    this.deleteRule = this.deleteRule.bind(this);
    this.updateChanged = this.updateChanged.bind(this);
    this.updateFromConstructor = this.updateFromConstructor.bind(this);
  }

  updateChanged() {
    this.setState({
      changed: true
    })
  }

  handleChange(field: 'xpath' | 'value' | 'type', value: string) {
    switch (field) {
      case "value":
        this.setState({value: value})
        break;
      case "xpath":
        this.setState({xpath: value, xpathValid: isXpathValid(value)})
        break;
      case 'type':
        // @ts-ignore
        this.setState({type: value})
    }
    this.updateChanged()
  }

  async updateRule() {
    this.setState({loading: true});

    let res = await editRule(this.props.rule.projectId, this.props.rule.id, {
      xpath: this.state.xpath,
      value: this.state.value,
      type: this.state.type
    })

    if (!res.ok) this.props.addToast(JSON.stringify(res), 'RuleComponent edit error');
    // else this.props.updateProject();
    this.setState({xpath: res.result.xpath, type: res.result.type, value: res.result.value, loading: false, changed: false})
    // this.setState({loading: false, changed: false});
  }

  async deleteRule() {
    let res = await deleteRule(this.props.rule.projectId, this.props.rule.id)
    if (!res.ok) this.props.addToast(JSON.stringify(res), 'RuleComponent delete error');
    else this.props.updateProject();
  }

  updateFromConstructor(xpath: string, type: string, value: string){
    this.setState({xpath, type, value, changed: true, xpathValid: isXpathValid(xpath)});
  }

  render() {
    return <Form className="my-4 border p-4 rounded">
      <Form.Group widths={'equal'} inline>
        <FormComponent
          placeholder='XPath'
          value={this.state.xpath}
          onChange={value => this.handleChange('xpath', value)}
        />
        <ConstructorRules
          xpath={this.state.xpath}
          format={this.props.format}
          action={this.state.type}
          value={this.state.value}
          updateRule={(xpath: string, action: string, value: string) => this.updateFromConstructor(xpath, action, value)}
        />
        <StatisticsModal value={this.state.value} xpath={this.state.xpath} projectId={this.props.rule.projectId}/>
      </Form.Group>
      <Form.Group widths={'equal'} inline>
        <Form.Select
          fluid
          placeholder='Тип'
          options={types}
          value={this.state.type}
          // @ts-ignore
          onChange={(e, data) => this.handleChange('type', data.value)}/>
        <FormComponent
          placeholder='Значение'
          value={this.state.value}
          onChange={value => this.handleChange('value', value)}
        />
        <ButtonGroup>
          <Button variant='success' disabled={this.props.isFirst}
                  onClick={() => this.props.changeOrder('up')}><FaArrowUp/></Button>
          <Button variant='success' disabled={this.props.isLast}
                  onClick={() => this.props.changeOrder('down')}><FaArrowDown/></Button>
          <Button variant="danger" onClick={() => this.deleteRule()}><FaTrash/></Button>
          <Button variant='success' disabled={!this.state.changed || !this.state.xpathValid || this.state.loading}
                  onClick={() => this.updateRule()}>{
            this.state.loading ? <Spinner variant='light' animation='grow'/> :
              <FaCheck/>}</Button>
        </ButtonGroup>
      </Form.Group></Form>

  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    addToast: (body: string, header?: string) => dispatch(addToast(body, header))
  }
}

export const RuleComponent = compose(connect(() => {
  return {}
}, mapDispatchToProps))(RuleComponent_)
