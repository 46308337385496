import React from "react";
import {withRouter} from "react-router-dom";
import {compose, Dispatch} from "redux";
import {connect} from "react-redux";
import {Button, ButtonGroup, Navbar, Spinner} from "react-bootstrap";
import {AppStateRedux, Mirror, Project, UserState} from "../../store/types";
import {BackButton} from "../../components/BackButton/BackButton";
import {generateProject, getProjectById} from "../../api/project";
import {FaExternalLinkAlt, FaSyncAlt} from "react-icons/all";
import {addToast} from "../../store/toast/actions";
import config from "../../config";
import {ProjectComponent} from "../../components/Project/Project";

interface ProjectPageProps {
  history: any;
  match: any;
  location: any;
  user: UserState;

  addToast(body: string, header?: string): void;
}

interface ProjectPageState {
  projectId: string;
  project: Project | null;
  error: string;
  loading: boolean;
  ok: boolean;
}


class ProjectPageComponent extends React.Component<ProjectPageProps, ProjectPageState> {
  constructor(props: ProjectPageProps) {
    super(props);

    this.state = {
      projectId: this.props.match.params.projectId,
      project: null,
      error: '',
      loading: false,
      ok: true
    }
    this.fetchProject = this.fetchProject.bind(this);
  }

  componentDidMount(): void {
    console.log('Project page', this.props.user)
    if (!this.props.user.isLogged) this.props.history.push('/login')
    this.fetchProject();
  }


  async fetchProject() {
    this.setState(Object.assign({}, this.state, {loading: true}))
    let res = await getProjectById(this.state.projectId);
    this.setState({ok: res.ok, loading: false})
    if (!res.ok) {
      if (res.code === 404000 || res.code === 400000)
        this.setState({error: 'Проект не найден'});
    } else {
      this.setState({loading: false, project: res.result});
      this.forceUpdate();
    }

  }

  async generate() {
    this.setState(Object.assign({}, this.state, {generating: true}))
    let res = await generateProject(this.state.projectId);
    if (res.ok) this.props.addToast('Проект будет сгенерирован в ближайшее время', 'Успешно!');
    else this.props.addToast(JSON.stringify(res), 'Ошибка');
    this.setState(Object.assign({}, this.state, {generating: false}))
    this.fetchProject()
  }

  render(): React.ReactNode {
    if (this.state.loading) {
      return (<p>Загрузка проекта...<Spinner animation='grow' variant='dark'/></p>)
    }

    return <div className="pr-4">
      <Navbar>
        <Navbar.Brand><BackButton onClick={() => this.props.history.push('/')}/></Navbar.Brand>
        <div
          style={{width: '100%', justifyContent: 'right', display: 'flex', alignItems: 'baseline', fontSize: '1.25em'}}>
          {this.props.user.user?.username}
          <Button variant="link">Выйти</Button>
        </div>
      </Navbar>
      {this.state.ok && this.state.project !== null ? <ProjectComponent project={this.state.project}/>: <p>{this.state.error}</p>}
    </div>
  }
}

function mapStateToProps(state: AppStateRedux) {
  return {
    user: state.user
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    addToast: (body: string, header?: string) => dispatch(addToast(body, header))
  }
}

export const ProjectPage = withRouter(compose(connect(mapStateToProps, mapDispatchToProps))(ProjectPageComponent))
