import React from "react";
import {Button, Icon, Modal, TextArea} from "semantic-ui-react";

interface FieldModalComponentProps {
  value: string;

  onChange(value: string): void;
}

interface FieldModalComponentState {
  value: string;
  open: boolean;
}

export class FieldModalComponent extends React.Component<FieldModalComponentProps, FieldModalComponentState> {
  constructor(props: FieldModalComponentProps) {
    super(props);

    this.state = {
      value: props.value,
      open: false
    }
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  close(saving: boolean = false, needConfirm: boolean = false){

    if (needConfirm){
      if (!this.confirm()) return
    }
    if (saving){
      this.props.onChange(this.state.value);
    } else {
      this.setState({value: this.props.value})
    }
    this.setState({open: false})
  }

  confirm(): boolean {
    // eslint-disable-next-line no-restricted-globals
    return confirm('Выйти без сохранения?');
  }

  onChange(value: string) {
    this.setState({value});
  }

  open(){
    this.setState({open: true})
  }

  componentDidUpdate(prevProps: Readonly<FieldModalComponentProps>, prevState: Readonly<FieldModalComponentState>, snapshot?: any): void {
    if (prevProps.value !== this.props.value){
      this.setState({value: this.props.value})
    }
  }

  render(): React.ReactNode {
    return <Modal
      style={{
        left: '50%',
        top: '50%',
        position: 'absolute',
        height: 'auto',
        transform: 'translateX(-50%) translateY(-50%)'
      }}
      open={this.state.open}
      onOpen={() => this.open()}
      onClose={() => this.close(false, true)}
      trigger={<Icon name='expand arrows alternate' link onClick={() => this.open()}/> }
      dimmer={true}
    >
      {/*<Modal.Header/>*/}
      <Modal.Content>
        <TextArea
          style={{
            width: '100%'
          }}
          // @ts-ignore
          onChange={(e, data) => this.onChange(data.value)}
          value={this.state.value}
          rows={7}
        />
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={() => this.close(false)}>Отмена</Button>
        <Button onClick={() => this.close(true)}>Сохранить</Button>
      </Modal.Actions>
    </Modal>
  }
}
