import {compose, Dispatch} from "redux";
import React from "react";
import {AppStateRedux} from "../../store/types";
import {Button, Form} from "semantic-ui-react";
import {convertGoogle, getEmail, haveAccess} from "../../api/parser";
import {addToast} from "../../store/toast/actions";
import {connect} from "react-redux";
import {download, formatsSelect} from "../../utils";
// import download from 'downloadjs'

interface GoogleConvertScreenState {
  email: string | null;
  emailLoading: boolean;

  urlValidating: boolean;
  urlValid: boolean;
  urlError: 'invalid' | 'no_access' | null;
  url: string;
  format: string;
  idFormat: string;
  loading: boolean;
}

interface GoogleConvertScreenProps {
  addToast(body: string, header: string): void;
}

const ERRORS = {
  'invalid': 'Недействительная ссылка',
  'no_access': 'Нет доступа'
}

class GoogleConvertScreenComponent extends React.Component<GoogleConvertScreenProps, GoogleConvertScreenState> {
  private timeout: NodeJS.Timeout | undefined;

  constructor(props: GoogleConvertScreenProps) {
    super(props);
    this.state = {
      email: null,
      emailLoading: false,
      urlValidating: false,
      urlValid: false,
      urlError: null,
      url: '',
      format: 'yandex',
      idFormat: 'uuid',
      loading: false
    }
    this.loadEmail();
  }

  async checkAccess(url: string) {
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(async () => {
      this.setState({urlValidating: true});
      let res = await haveAccess(url);
      if (res.ok) this.setState({urlValid: res.result.access, urlError: res.result.reason})
      else this.props.addToast(JSON.stringify(res), 'Ошибка!')
      this.setState({urlValidating: false});
    }, 1000)
  }

  async loadEmail() {
    this.setState({emailLoading: true});
    let res = await getEmail();
    if (res.ok) this.setState({email: res.result})
    else this.props.addToast(JSON.stringify(res), 'Ошибка!')
    this.setState({emailLoading: false});
  }

  async doConvert() {
    this.setState({loading: true});
    let res = await convertGoogle(this.state.url, this.state.format, this.state.idFormat)
    if (!res.ok) this.props.addToast(JSON.stringify(res), 'Ошибка');
    // @ts-ignore
    else download(res.result.xml, new Date().toISOString().replace(/ /g, '-') + '_convert.xml', 'text/xml')
    this.setState({loading: false})
  }

  render() {
    return <div>
      <Form loading={this.state.loading}>
        <Form.Input label="Ссылка на таблицу" error={this.state.urlError !== null ? ERRORS[this.state.urlError] : null}
                    loading={this.state.urlValidating}
                    onChange={(e, data) => {
                      this.setState({url: data.value});
                      this.checkAccess(data.value)
                    }}/>
        <p>Проверьте, что таблица доступна для
          пользователя {this.state.emailLoading ? 'Загрузка...' : this.state.email}</p>
        <Form.Select options={formatsSelect} value={this.state.format} label="Формат XML"
                     onChange={(e, data) =>
                       this.setState({format: data.value ? data.value.toString() : 'yandex'})}/>

        <Form.Select options={[{text: 'UUID', value: 'uuid'}, {text: 'Число', value: 'integer'}]}
                     value={this.state.idFormat}
                     label="Тип ID"
                     onChange={(e, data) =>
                       this.setState({idFormat: data.value ? data.value.toString() : 'uuid'})}/>
        <Button disabled={!this.state.urlValid} content={'Конвертировать'} color={'blue'}
                onClick={() => this.doConvert()}/>

      </Form>
    </div>
  }
}

const mapStateToProps = (state: AppStateRedux) => {
  return {
    // projects: state.projects.projects.map(p => {
    //   return {
    //     id: p.id, title: p.title
    //   }
    // })
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    addToast: (body: string, header?: string) => dispatch(addToast(body, header))
  }
}

export const GoogleConvertScreen = compose(connect(mapStateToProps, mapDispatchToProps))(GoogleConvertScreenComponent)
