import {ApiResponse, ApiResponsePagination} from "./types";
import axios from "axios";
import {Mirror} from "../store/types";
import {url} from "./utils";


export async function createMirror(projectId: string, hash: string): Promise<ApiResponse<Mirror>> {
  try {
    return (await axios.post(url('/project/', projectId, '/mirror'), {hash})).data;
  } catch (e) {
    return e.response.data;
  }
}


export async function getMirrors(projectId: string): Promise<ApiResponsePagination<{mirrors: Mirror[]}>> {
  try {
    return (await axios.get(url('/project/', projectId, '/mirrors'))).data;
  } catch (e) {
    return e.response.data;
  }
}


export async function deleteMirror(projectId: string, mirrorId: string): Promise<ApiResponse<undefined>>{
  try {
    return (await axios.delete(url('/project/', projectId, '/mirror/', mirrorId) )).data;
  } catch (e) {
    return e.response.data;
  }
}
