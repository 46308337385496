import React from "react";
import {Statistic} from "../../store/types";
import {getStatistics} from "../../api/statistics";
import {Button, Icon, Modal, Table} from "semantic-ui-react";
import {LoadingSpinner} from "../Spinner/Spinner";
import {Alert} from "react-bootstrap";

interface StatisticsModalProps {
  value: string;
  xpath: string;
  projectId: string;
}

interface StatisticsModalState {
  statistics: Statistic[];
  error: string | null;
  loading: boolean;
  show: boolean;
}

export class StatisticsModal extends React.Component<StatisticsModalProps, StatisticsModalState> {
  constructor(props: StatisticsModalProps) {
    super(props);

    this.state = {
      loading: false,
      statistics: [],
      error: null,
      show: false
    }
    this.updateStatistics();
  }

  async updateStatistics() {
    this.setState({loading: true})
    let xpath = this.props.xpath;
    let res = await getStatistics(xpath.replace(/\[.*\]+/g, ''), this.props.value);
    if (res.ok) {
      this.setState({statistics: res.result});
      this.setState({error: null})
    } else {
      this.setState({error: res.msg})
    }
    this.setState({loading: false})
  }

  componentDidUpdate(prevProps: Readonly<StatisticsModalProps>, prevState: Readonly<StatisticsModalState>, snapshot?: any): void {
    if (prevProps.xpath !== this.props.xpath || prevProps.value !== this.props.value) {
      this.updateStatistics();
    }
  }

  render(): React.ReactNode {
    return <Modal
      style={{
        left: '50%',
        top: '50%',
        position: 'absolute',
        height: 'auto',
        transform: 'translateX(-50%) translateY(-50%)'
      }}
      trigger={
        <Button onClick={() => this.setState({show: true})}
        >Статистика({
          this.state.loading ? 'Загрузка' : this.state.statistics.length
        })</Button>}
      onClose={() => this.setState({show: false})}
      open={this.state.show}
      closeIcon={<Icon name="close"/>}
    >
      <Modal.Header>Статистика</Modal.Header>
      <Modal.Content>
        {this.state.loading ?
          <LoadingSpinner/> :
          this.state.error !== null ?
            <Alert variant="danger">{this.state.error}</Alert> :
            null
        }
        {!this.state.loading && this.state.error === null && this.state.statistics.length === 0 ? <i>Нет похожих записей...</i> : null}
        {!this.state.loading && this.state.error === null && this.state.statistics.length > 0 ?
          <Table>
            <thead>
            <tr>
              <th>Поле</th>
              <th>Значение</th>
              <th>Количество</th>
              <th>Проект</th>
            </tr>
            </thead>
            <tbody>
            {this.state.statistics.map((s, i) => <tr key={i}>
              <td>{s.field}</td>
              <td>{s.value}</td>
              <td>{s.count}</td>
              <td><a target="_blank" rel="noopener noreferrer"
                     href={'/project/' + s.project.id}>{s.project.title}</a>{this.props.projectId === s.project.id ? ' (Этот проект)' : null}
              </td>
            </tr>)}
            </tbody>
          </Table> : null
        }
      </Modal.Content>
    </Modal>
  }
}
