import React from "react";
import {AppStateRedux, Group, Mirror, Project, ProjectHistory, ProjectSettings, Rule} from "../../store/types";
import {Button, Tab, Tabs} from "react-bootstrap";
import {ProjectRules} from "../ProjectRules/ProjectRules";
import {ProjectMirrors} from "../ProjectMirrors/ProjectMirrors";
import {generateProject, getProjectById} from "../../api/project";
import {compose, Dispatch} from "redux";
import {connect} from "react-redux";
import {addToast} from "../../store/toast/actions";
import {ButtonGroup, Icon} from "semantic-ui-react";
import config from "../../config";
import {ProjectHistoryComponent} from "../ProjectHistory/ProjectHistory";
import {ProjectStaticComponent} from "../ProjectStatic/ProjectStatic";
import {ProjectSettingsPanel} from "../ProjectSettings/ProjectSettings";
import {ProjectGroups} from "../ProjectGroups/ProjectGroups";

interface ProjectProps {
	project: Project

	addToast(body: string, header: string): any
}

interface ProjectState {
	history: ProjectHistory[];
	mirrors: Mirror[];
	rules: Rule[];
	groups: Group[];
	title: string;
	url: string;
	static: string | null;
	settings: ProjectSettings;
	currentTab: string | null;
	generating: boolean;
	loading: boolean
}

class ProjectComponent_ extends React.Component<ProjectProps, ProjectState> {
	constructor(props: ProjectProps) {
		super(props);
		this.state = {
			history: props.project.history,
			mirrors: props.project.mirrors,
			rules: props.project.rules,
			title: props.project.title,
			url: props.project.url,
			settings: props.project.settings,
			static: props.project.static !== null ? props.project.static.xml : null,
			groups: props.project.groups,

			currentTab: 'rules',
			generating: false,
			loading: false
		}
		this.updateRules = this.updateRules.bind(this);
		this.updateMirrors = this.updateMirrors.bind(this);
		this.updateStatic = this.updateStatic.bind(this);
		this.updateSettings = this.updateSettings.bind(this);
		this.updateGroups = this.updateGroups.bind(this);
	}

	async generate() {
		this.setState({generating: true})
		let res = await generateProject(this.props.project.id);
		if (res.ok) this.props.addToast('Проект будет сгенерирован в ближайшее время', 'Успешно!');
		else this.props.addToast(JSON.stringify(res), 'Ошибка');
		this.setState({generating: false})
		this.updateProject()
	}

	async updateProject() {
		this.setState({loading: true})
		let res = await getProjectById(this.props.project.id)
		if (res.ok)
			this.setState({
				rules: res.result.rules,
				mirrors: res.result.mirrors,
				settings: res.result.settings,
				history: res.result.history,
				static: res.result.static !== null ? res.result.static.xml : null
			})
		else this.props.addToast(JSON.stringify(res), 'Ошибка')
		this.setState({loading: false});
	}

	updateRules(rules: Rule[]) {
		this.setState({rules})
	}

	updateMirrors(mirrors: Mirror[]) {
		this.setState({mirrors})
	}

	updateStatic(xml: string | null) {
		this.setState({static: xml})
	}

	updateSettings(title: string, url: string, settings: ProjectSettings) {
		this.setState({title, url, settings})
	}

	updateGroups(groups: Group[]) {
		this.setState({groups});
	}

	render() {
		return <div>
			<div className='justify-content-center my-2'>
				<ButtonGroup>
					<Button
						variant='success'
						onClick={() => this.generate()}
						disabled={this.state.generating}
					><Icon name='refresh'/>Сгенерировать проект</Button>
					<Button
						href={config.xmlUrl + "/" + this.props.project.id + '.xml'}
						variant='primary'
						target='_blank'
					><Icon name='external'/>Открыть в новой вкладке</Button>
				</ButtonGroup>
			</div>
			<h5>{this.state.title}</h5>
			<Tabs activeKey={this.state.currentTab} onSelect={(k) => this.setState({currentTab: k})}>
				<Tab eventKey='rules' title='Правила'>
					<ProjectRules rules={this.state.rules} projectId={this.props.project.id} updateRules={this.updateRules}
					              format={this.state.settings.format}/>
				</Tab>
				<Tab eventKey='mirrors' title='Зеркала'>
					<ProjectMirrors mirrors={this.state.mirrors} projectId={this.props.project.id}
					                updateMirrors={this.updateMirrors}/>
				</Tab>
				<Tab eventKey='history' title='История'>
					<ProjectHistoryComponent history={this.state.history}/>
				</Tab>
				<Tab eventKey='static' title='Статические данные'>
					<ProjectStaticComponent xml={this.state.static} updateStatic={this.updateStatic}
					                        projectId={this.props.project.id}/>
				</Tab>
				<Tab eventKey='groups' title='Группы'>
					<ProjectGroups selectedGroups={this.state.groups} projectId={this.props.project.id}
					               updateGroups={this.updateGroups}/>
				</Tab>
				<Tab eventKey='settings' title='Настройки'>
					<ProjectSettingsPanel updateProject={this.updateSettings} settings={this.state.settings}
					                      projectId={this.props.project.id}
					                      url={this.state.url} title={this.state.title}/>
				</Tab>
			</Tabs>
		</div>
	}
}

function mapStateToProps(state: AppStateRedux) {
	return {}
}

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		addToast: (body: string, header: string) => dispatch(addToast(body, header))
	}
}

export const ProjectComponent = compose(connect(mapStateToProps, mapDispatchToProps))(ProjectComponent_)
