import {compose, Dispatch} from "redux";
import {connect} from "react-redux";
import {AppStateRedux, ProjectSettings} from "../../store/types";
import React, {SyntheticEvent} from "react";
import {getAllProjects, updateProject} from "../../api/project";
import {addToast} from "../../store/toast/actions";
import errors from "../../api/errors";
import {Button, DropdownProps, Form} from "semantic-ui-react";
import {Formats, formatsSelect} from "../../utils";
import {ListGroup} from "react-bootstrap";
import {FaTrash} from "react-icons/all";

interface ProjectSettingsProps {
  addToast(body: string, header?: string): void;

  updateProject(title: string, url: string, settings: ProjectSettings): void;

  settings: ProjectSettings;
  projectId: string;
  url: string;
  title: string;
}

interface ProjectSettingsState {
  loading: boolean;
  format: Formats;
  outputFormat: Formats;
  url: string;
  title: string;
  statistic: string[];
  reverseRules: boolean;
  newStatistic: string;
}

class ProjectSettingsComponent extends React.Component<ProjectSettingsProps, ProjectSettingsState> {
  constructor(props: ProjectSettingsProps) {
    super(props);
    // if (props.settings !== null)
    this.state = {
      loading: false,
      format: props.settings.format,
      url: props.url,
      title: props.title,
      statistic: props.settings.statistic,
      reverseRules: props.settings.reverseRules,
      outputFormat: props.settings.outputFormat,
      newStatistic: ''
    }
    this.selectType = this.selectType.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateProject = this.updateProject.bind(this);
    this.addStatistic = this.addStatistic.bind(this)
    this.deleteStatistic = this.deleteStatistic.bind(this)
  }

  handleChange(field: 'url' | 'title' | 'reverseRules' | 'newStatistic', value: string) {
    if (field === "reverseRules") {
      this.setState({reverseRules: value === 'true'})
    } else {
      // @ts-ignore
      this.setState({[field]: value});
    }
  }

  selectType(field: 'format' | 'outputFormat',data: DropdownProps) {
    if (typeof data.value === "string") {
      // @ts-ignore
      this.setState({[field]: data.value})
    }
  }

  addStatistic() {
    this.setState({statistic: [...this.state.statistic, this.state.newStatistic]})
  }

  deleteStatistic(xpath: string) {
    let index = this.state.statistic.indexOf(xpath);
    if (index !== -1) {
      let arr = [...this.state.statistic];
      arr.splice(index, 1);
      this.setState({statistic: arr})
    }
  }

  async updateProject() {
    this.setState({loading: true})
    let res = await updateProject(this.props.projectId, {
      title: this.state.title,
      url: this.state.url,
      settings: {
        reverseRules: this.state.reverseRules,
        statistic: this.state.statistic,
        format: this.state.format,
        outputFormat: this.state.outputFormat
      }
    })
    if (!res.ok) this.props.addToast(JSON.stringify(res), 'Ошибка')
    if (res.ok) this.props.updateProject(this.state.title, this.state.url, {
      format: this.state.format,
      statistic: this.state.statistic,
      reverseRules: this.state.reverseRules,
      outputFormat: this.state.outputFormat
    });
    this.setState({loading: false});
  }

  render(): React.ReactNode {
    return <div className='m-3 p-3'>
      <Form loading={this.state.loading}>
        <Form.Input name='title' label='Название' value={this.state.title}
                    onChange={(e, data) => this.handleChange('title', data.value)}/>
        <Form.Input name='url' label='Ссылка на источник' value={this.state.url}
                    onChange={(e, data) => this.handleChange('url', data.value)}/>
        <Form.Select name='format' label='Изначальный формат' value={this.state.format} options={formatsSelect}
                     onChange={(e, data) => this.selectType('format', data)}/>
        <Form.Select name='outputFormat' label='Выходной формат' value={this.state.outputFormat} options={formatsSelect}
                     onChange={(e, data) => this.selectType('outputFormat', data)}/>
        <Form.Checkbox type='checkbox' name='reverseRules' label='Правила в обратном порядке'
                       value={this.state.reverseRules.toString()}
                       onChange={(e, data) => this.handleChange('reverseRules', data.value ? data.value.toString() : 'false')}/>
        <h5>Статистика:</h5>
        <Form.Input name='newStatistic' label='Добавить новое поле в отслеживание' value={this.state.newStatistic}
                    onChange={(e, data) => this.handleChange('newStatistic', data.value)}/>
        <Button onClick={() => this.addStatistic()} color='green'>Добавить</Button>
        <ListGroup>
          {this.state.statistic.map(statistic =>
            <ListGroup.Item>
              {statistic} <Button onClick={() => this.deleteStatistic(statistic)} color='red'><FaTrash/></Button>
            </ListGroup.Item>)}
        </ListGroup>

        <Button loading={this.state.loading} onClick={(e) => {
          e.preventDefault();
          this.updateProject()
        }}>Обновить проект</Button>
      </Form>
    </div>
  }
}

function mapStateToProps(state: AppStateRedux) {
  return {}
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    addToast: (body: string, header?: string) => dispatch(addToast(body, header))
  }
}

export const ProjectSettingsPanel = compose(connect(mapStateToProps, mapDispatchToProps))(ProjectSettingsComponent)
