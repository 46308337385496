import {AppStateRedux, Rooms} from "../../store/types";
import {compose, Dispatch} from "redux";
import {addToast} from "../../store/toast/actions";
import React from "react";
import {connect} from "react-redux";
import {Button, Dropdown, Form} from "semantic-ui-react";
import config from "../../config";
import {convertXml, countRooms} from "../../api/parser";
import {Col} from "react-bootstrap";
import {download, formatsSelect} from "../../utils";

const mapStateToProps = (state: AppStateRedux) => {
  return {
    projects: state.projects.projects.map(p => {
      return {
        id: p.id, title: p.title
      }
    })
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    addToast: (body: string, header?: string) => dispatch(addToast(body, header))
  }
}

interface Project_ {
  id: string,
  title: string
}


interface XmlConvertScreenProps {
  projects: Project_[];

  addToast(body: string, header?: string): void;
}

interface XmlConvertScreenState {
  selectedProject: Project_ | null;
  url: string;

  rooms: Rooms;
  filters: Rooms;
  format: string;
  loading: boolean;
  countingRooms: boolean;

}


class XmlConvertScreenComponent extends React.Component<XmlConvertScreenProps, XmlConvertScreenState> {
  private timeout: any;

  constructor(props: XmlConvertScreenProps) {
    super(props);
    this.state = {
      rooms: {'1': 0, '2': 0, '3': 0, '4': 0, 'studio': 0},
      selectedProject: null,
      format: 'yandex',
      filters: {'1': 0, '2': 0, '3': 0, '4': 0, 'studio': 0},
      loading: false,
      countingRooms: false,
      url: ''
    }
    this.selectFromProjects = this.selectFromProjects.bind(this);
    this.countRooms = this.countRooms.bind(this);
    this.updateUrl = this.updateUrl.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
  }

  updateUrl(url: string) {
    this.setState({url});
    this.countRooms(url);
  }

  selectFromProjects(p: Project_) {
    let url = config.xmlUrl + '/' + p.id + '.xml'
    this.setState({selectedProject: p});
    this.updateUrl(url)
  }

  async countRooms(url: string) {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      this.setState({countingRooms: true});
      let res = await countRooms(url);
      if (!res.ok) {
        // @ts-ignore
        this.props.addToast(res.data['err'], 'Ошибка');
      } else {
        this.setState({rooms: Object.assign({}, res.result, {all: undefined})})
      }
      this.setState({countingRooms: false})
    }, 700)
  }

  updateFilter(key: string, value: number) {
    // @ts-ignore
    this.setState({filters: Object.assign({}, this.state.filters, {[key]: value})});
  }

  async doConvert() {
    this.setState({loading: true});
    // @ts-ignore
    let filters = Object.keys(this.state.filters).filter(key => key !== 'studio' && key !== 'all' && this.state.filters[key] > 0).map(key => {
      return {
        value: key,
        // @ts-ignore
        limit: this.state.filters[key]
      }
    })
    let res = await convertXml(this.state.url, filters, this.state.format);
    if (!res.ok) this.props.addToast(JSON.stringify(res), 'Ошибка');
    // @ts-ignore
    else download(res.result.xml, new Date().toISOString().replace(/ /g, '-') + '_convert.xml', 'text/xml')

    this.setState({loading: false})
  }

  render(): React.ReactNode {
    return <div className="my-3">
      <Form loading={this.state.loading}>
        <Form.Group inline>
          <Dropdown
            placeholder={this.state.selectedProject === null ? 'URL' : `URL(${this.state.selectedProject.title})`}
            width={3}
            options={this.props.projects.map(p => {
              return {key: p.id, value: JSON.stringify(p), text: p.title}
            })} search selection
            onChange={(e, data) => data.value ? this.selectFromProjects(JSON.parse(data.value.toString())) : null}/>
          <Form.Input type={'integer'} fluid value={this.state.url} width={13} loading={this.state.countingRooms}
                      onChange={(e, data) => this.updateUrl(data.value)}/>
        </Form.Group>
        <Col>
          {/* @ts-ignore */}
          {Object.keys(this.state.rooms).filter(key => this.state.rooms[key] > 0).map(key => <Form.Input
            key={key}
            // @ts-ignore
            label={(key !== 'studio' ? key + ' комната(-ы)' : 'Студии') + `(${this.state.rooms[key]})`}
            // @ts-ignore
            value={this.state.filters[key]} onChange={(e, data) => this.updateFilter(key, data.value)}/>)}
        </Col>
        <Form.Group inline>
          <Form.Select label="Формат XML" options={formatsSelect} value={this.state.format}
                       onChange={(e, data) => this.setState({format: data.value ? data.value.toString() : 'yandex'})}/>
          <Button color={'blue'} content={'Конвертировать'} onClick={() => this.doConvert()}/>
        </Form.Group>

      </Form>
    </div>
  }
}

export const XmlConvertScreen = compose(connect(mapStateToProps, mapDispatchToProps))(XmlConvertScreenComponent);
