import React from "react";
import 'semantic-ui-css/semantic.min.css'
import {BrowserRouter, Route} from "react-router-dom";
import Switch from "react-bootstrap/Switch";
import {LoginPage} from "./panels/LoginPage";
import {LoadingSpinner} from "./components/Spinner/Spinner";
import {compose, Dispatch} from "redux";
import {connect} from "react-redux";
import {AppStateRedux, Group, ToastState, User} from "./store/types";
import {Toast} from "react-bootstrap";
import {addToast, hideToast} from "./store/toast/actions";
import {ProfilePage} from "./panels/ProfilePage";
import Cookies from "js-cookie";
import axios from 'axios';
import {AddProjectModal} from "./components/AddProjectModal/AddProjectModal";
import {ProjectPage} from "./panels/ProjectPage/ProjectPage";
import {getMe} from "./api/auth";
import errors from "./api/errors";
import {userLogin, userSet} from "./store/user/actions";
import {groupsSet} from "./store/project/actions";
import {getUserGroups} from "./api/groups";

interface AppState {
  loading: boolean
}

interface AppProps {
  toasts: ToastState[];
  showAddModal: boolean;

  hideToast(index: number): void;

  setUser(user: User): void;

  addToast(body: string, header?: string): void;

  userLogin(): void;

  setGroups(groups: Group[]): void;
}

class AppComponent extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {loading: true}
    this.updateUser = this.updateUser.bind(this)
    this.updateGroups = this.updateGroups.bind(this)
  }


  async componentDidMount() {
    if (Cookies.get('access-token')) {
      axios.defaults.headers = {
        'Authorization': 'Bearer ' + Cookies.get('access-token')
      }
      let res = await getMe();

      if (!res.ok) {
        Cookies.remove('access-token')
        if (document.location.pathname !== '/login')
          document.location.pathname = '/login';
        return this.props.addToast(errors(res.code))
      }
      this.props.setUser(res.result);
      this.props.userLogin();
    } else {
      if (document.location.pathname !== '/login')
        document.location.pathname = '/login';
    }
    this.updateGroups();
    this.setState({loading: false});
  }

  async updateUser(): Promise<void> {
    let res = await getMe();
    if (!res.ok) {
      Cookies.remove('access-token')
      document.location.pathname = '/login';
      return this.props.addToast(errors(res.code))
    }

    this.props.setUser(res.result);
  }

  async updateGroups() {
    let res = await getUserGroups();
    if (res.ok)
      this.props.setGroups(res.result.groups)
  }

  render() {
    return (
      <div className="h-100">{
        this.state.loading ? <LoadingSpinner/> :

          <BrowserRouter>
            <React.Suspense fallback={<div><LoadingSpinner text='Loading...'/></div>}>
              <Switch>
                <Route path="/login" component={LoginPage}/>
                <Route path="/" exact component={ProfilePage}/>
                <Route path='/project/:projectId' component={ProjectPage}/>
              </Switch>
            </React.Suspense>
            <div style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              padding: '10px 10px 10px 10px'
            }}>
              {
                this.props.toasts.map((toast, i) =>
                  <Toast autohide onClose={() => this.props.hideToast(i)}>
                    <Toast.Header>
                      {toast.header ? <strong>{toast.header}</strong> : null}
                    </Toast.Header>
                    <Toast.Body>{toast.body}</Toast.Body>
                  </Toast>)
              }
              <AddProjectModal/>
            </div>
          </BrowserRouter>

      } </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    hideToast: (index: number) => dispatch(hideToast(index)),
    setUser: (user: User) => dispatch(userSet(user)),
    addToast: (body: string, header?: string) => dispatch(addToast(body, header)),
    userLogin: () => dispatch(userLogin()),
    setGroups: (groups: Group[]) => dispatch(groupsSet(groups)),
  }
}

const mapStateToProps = (state: AppStateRedux) => {
  return {
    toasts: state.toasts,
    showAddModal: state.projects.showAddModal
  }
}

export const App = compose(connect(mapStateToProps, mapDispatchToProps))(AppComponent)
