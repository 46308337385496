import {Action, Group, GroupsMap, Project, ProjectsByGroup, ProjectsMap, ProjectState} from "../types";
import {SET_GROUPS, SET_PROJECTS, TOGGLE_MODAL} from "./actions";

function setProjects(state: ProjectState, projects: Project[], count: number): ProjectState {
  let projectsMap: ProjectsMap = {};
  let groupsMap: GroupsMap = {'null': {title: 'Нераспределенные проекты', id: 'null', projectsCount: 0}};
  let projectsByGroup: ProjectsByGroup = {'null': []};

  for (const project of projects) {
    if (project.groups.length === 0) {
      groupsMap['null'].projectsCount++;
      projectsByGroup['null'].push(project);
    } else {
      for (const group of project.groups){
        if (groupsMap[group.id] === undefined)
          groupsMap[group.id] = group
        if (projectsByGroup[group.id] === undefined)
          projectsByGroup[group.id] = []
        projectsByGroup[group.id].push(project)
      }
    }
    projectsMap[project.id] = project
  }

  return {projects, count, projectsById: projectsMap, showAddModal: state.showAddModal, groupsMap, projectsByGroup, groups: state.groups}
}

function setGroups(state: ProjectState, groups: Group[]): ProjectState{
  return Object.assign({}, state, {groups});
}

function toggleModal(state: ProjectState, show: boolean): ProjectState {
  return Object.assign({}, state, {showAddModal: show});
}

export function projectReducer(state: ProjectState = {
  count: 0,
  projectsByGroup: {},
  groupsMap: {},
  groups: [],
  projects: [],
  projectsById: {},
  showAddModal: false
}, action: Action): ProjectState {
  switch (action.type) {
    case SET_GROUPS:
      return setGroups(state, action.groups);
    case SET_PROJECTS:
      return setProjects(state, action.projects, action.count);
    case TOGGLE_MODAL:
      return toggleModal(state, action.show);
    default:
      return state;
  }
}
