import React, {ChangeEvent} from "react";
import {AppStateRedux} from "../../store/types";
import {Button, Modal} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {compose, Dispatch} from "redux";
import {toggleAddProjectModal} from "../../store/project/actions";
import {Dropdown, Form, InputOnChangeData} from "semantic-ui-react";
import {createProject} from "../../api/project";
import {addToast} from "../../store/toast/actions";
import errors from "../../api/errors";
import {getSuggestedLinks, SuggestedLink} from "../../api/utils";

interface AddProjectModalProps {
	show: boolean;
	history: any;
	match: any;
	location: any;

	toggleAddProjectModal(show: boolean): void;

	addToast(body: string, header?: string): void;
}

interface AddProjectModalState {
	loading: boolean;
	title: string | null;
	url: string | null;
	titleValid: boolean;
	urlValid: boolean;
	suggestedLinks: Array<{ key: string, text: string, value: string }>;
	suggestedLinksLoading: boolean;
}

interface FieldChange {
	name: string;
	value: string;
}

class AddProjectModalComponent extends React.Component<AddProjectModalProps, AddProjectModalState> {
	constructor(props: AddProjectModalProps) {
		super(props);

		this.state = {
			loading: false,
			title: null,
			titleValid: true,
			url: null,
			urlValid: true,
			suggestedLinks: [],
			suggestedLinksLoading: true,
		}
		this.handleChange = this.handleChange.bind(this);
		this.create = this.create.bind(this);
		this.updateSuggestedLinks = this.updateSuggestedLinks.bind(this);
	}

	componentDidMount() {
		this.updateSuggestedLinks();
	}

	async updateSuggestedLinks() {
		this.setState({suggestedLinksLoading: true})
		let res = await getSuggestedLinks();
		if (!res.ok) this.setState({suggestedLinks: []})
		else this.setState({suggestedLinks: res.result.map(sl => ({key: sl.url, text: sl.name, value: sl.url}))});
		this.setState({suggestedLinksLoading: false})
	}

	handleChange(e: ChangeEvent<HTMLInputElement>, field: InputOnChangeData) {
		e.preventDefault();
		switch (field.name) {
			case 'url':
				this.setState(Object.assign({}, this.state, {url: field.value, urlValid: field.value.length !== 0}));
				break;
			case 'title':
				this.setState(Object.assign({}, this.state, {title: field.value, titleValid: field.value.length !== 0}));
				break;
		}
	}

	async create() {

		if (!this.state.title || !this.state.url) {

			return this.props.addToast('Сначала заполните все необходимые поля');
		}
		this.setState(Object.assign({}, this.state, {loading: true}))
		let res = await createProject({title: this.state.title, url: this.state.url});
		this.setState(Object.assign({}, this.state, {loading: false}));
		this.props.toggleAddProjectModal(false);
		this.props.addToast('Проект успешно создан');
		if (!res.ok) {
			this.props.addToast(errors(res.code));
		}
		this.props.history.push('/project/' + res.result.id);

	}

	render(): React.ReactNode {
		return (<Modal show={this.props.show} onHide={() => this.props.toggleAddProjectModal(false)}>
			<Modal.Header closeButton><Modal.Title>Добавление проекта</Modal.Title></Modal.Header>
			<Modal.Body>
				<div>
					<Form loading={this.state.loading}>
						<Form.Input required name="title" label="Название проекта" onChange={this.handleChange}
						            error={!this.state.titleValid}/>
						<Form.Input required name="url" onChange={this.handleChange} value={this.state.url}
						            error={!this.state.urlValid}
						            label="Ссылка на источник:"
						            actionPosition="left"
						            action={<Dropdown loading={this.state.suggestedLinksLoading} search text="URL:" button basic
						                              floating
						                              onChange={(e, {value}) => this.setState({url: value ? value.toString() : null})}
						                              noResultsMessage="Нет ссылок"
						                              options={this.state.suggestedLinks}/>}
						/>
					</Form>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="success" disabled={!(this.state.titleValid && this.state.urlValid) || this.state.loading}
				        onClick={() => this.create()}>Создать проект</Button>
			</Modal.Footer>
		</Modal>)
	}
}

function mapStateToProps(state: AppStateRedux) {
	return {
		show: state.projects.showAddModal
	}
}

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		toggleAddProjectModal: (show: boolean) => dispatch(toggleAddProjectModal(show)),
		addToast: (body: string, header?: string) => dispatch(addToast(body, header)),
	}
}

export const AddProjectModal = withRouter(compose(connect(mapStateToProps, mapDispatchToProps))(AddProjectModalComponent))
