export const tags = {
  yandex: {
    "!top":["offer"],
    offer: {
      attrs: {
        "internal-id": null
      },
      children: ["type", "property-type", "category", "url", "creation-date", "last-update-date", "location", "sales-agent", "deal-status", "price", "image", "area", "living-space", "renovation", "description", "new-flat", "floor", "rooms", "floors-total", "building-name", "built-year", "ready-quarter", "building-state", "yandex-building-id", "yandex-house-id", "building-type"]
    },
    location: {
      children: ["country", "region", "district", "locality-name", "latitude", "longitude", "metro"]
    },
    metro: {
      children: ["name", "time-on-transport", "time-on-foot"]
    },
    "sales-agent": {
      children: ["name", "phone", "organization", "url", "category", "photo"]
    },
    price: {
      children: ["value", "currency"]
    },
    area: {
      children: ["value", "unit"]
    },
    "living-space": {
      children: ["value", "unit"]
    }
  }

}
