import axios from "axios";
import {url} from "./utils";
import {ApiResponse} from "./types";
import {Rooms} from "../store/types";

export async function getEmail(): Promise<ApiResponse<string>> {
  try {
    return (await axios.get(url('/parser/google/email'))).data;
  } catch (e) {
    return e.response.data;
  }
}

export async function haveAccess(googleUrl: string): Promise<ApiResponse<{access: boolean, reason: 'invalid' | 'no_access' | null}>> {
  try {
    return (await axios.post(url('/parser/google/access'), {url: googleUrl})).data;
  } catch (e) {
    return e.response.data;
  }
}

export async function convertGoogle(googleUrl: string, format: string, idFormat: string): Promise<ApiResponse<{ xml: string }>> {
  try {
    return (await axios.post(url('/parser/google/convert'), {url: googleUrl, format, id_format: idFormat})).data;
  } catch (e) {
    return e.response.data;
  }
}

export async function countRooms(xmlUrl: string): Promise<ApiResponse<Rooms>> {
  try {
    return (await axios.post(url('/parser/count_rooms'), {url: xmlUrl})).data;
  } catch (e) {
    return e.response.data;
  }
}

export async function convertXml(xmlUrl: string, filter: any, format: string) {
  try {
    return (await axios.post(url('/parser/convert'), {url: xmlUrl, filter: filter, format})).data;
  } catch (e) {
    return e.response.data;
  }
}
