import React from "react";
import {FormInput} from "semantic-ui-react";
import {FieldModalComponent} from "./FieldModal";

interface FormComponentProps {
  value: string;
  placeholder?: string;

  onChange(value: string): void;
}

interface FormComponentState {
  value: string;
}

export class FormComponent extends React.Component<FormComponentProps, FormComponentState> {
  constructor(props: FormComponentProps) {
    super(props);

    this.state = {
      value: props.value
    }
    this.onChange = this.onChange.bind(this)
  }

  onChange(value: string) {
    this.setState({value});
    this.props.onChange(value);
  }

  componentDidUpdate(prevProps: Readonly<FormComponentProps>, prevState: Readonly<FormComponentState>, snapshot?: any): void {
    if (this.props.value !== prevProps.value){
      this.setState({value: this.props.value})
    }
  }

  render(): React.ReactNode {
    return <FormInput
      fluid
      icon={
        <FieldModalComponent
          value={this.state.value}
          onChange={this.onChange}/>
      }
      value={this.state.value}
      placeholder={this.props.placeholder}
      onChange={(e, data) => this.onChange(data.value)}
    />
  }
}
