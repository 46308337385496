import config from "../config";
import {ApiResponse} from "./types";
import axios from "axios";

export function url(...parts: string[]) {
  return config.api.baseUrl + parts.join('');
}

export interface SuggestedLink {
  name: string;
  url: string;
}

export async function getSuggestedLinks(): Promise<ApiResponse<Array<SuggestedLink>>>{
  try {
    return (await axios.get(url('/utils/suggestedLinks'))).data;
  } catch (e) {
    return e.response.data;
  }
}
