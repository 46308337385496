export const ADD_TOAST = 'ADD_TOAST';

export function addToast(body: string, header?: string){
  return {
    type: ADD_TOAST,
    header,
    body
  }
}

export const HIDE_TOAST = 'HIDE_TOAST';

export function hideToast(index: number) {
  return {
    type: HIDE_TOAST,
    index
  }
}
