import {combineReducers} from "redux";
import {userReducer} from "./user/reducer";
import {toastReducer} from "./toast/reducer";
import {projectReducer} from "./project/reducer";


const rootReducer = combineReducers({
  user: userReducer,
  toasts: toastReducer,
  projects: projectReducer
})

export default rootReducer
