import React, {SyntheticEvent} from "react";
import {AppStateRedux, Rule, RuleTypes, types} from "../../store/types";
import {Form} from "semantic-ui-react";
import {ButtonGroup, Dropdown, DropdownButton, DropdownProps, Spinner} from "react-bootstrap";
import {FaPlus} from "react-icons/all";
import {array_move, Formats, isXpathValid} from "../../utils";
import {createRule, editRuleOrder, getRules} from "../../api/rule";
import {compose, Dispatch} from "redux";
import {addToast} from "../../store/toast/actions";
import {connect} from "react-redux";
import {FormComponent} from "../Field/Field";
import {RuleComponent} from "../RuleComponent/RuleComponent";

interface ProjectRulesProps {
  rules: Rule[];
  projectId: string;
  format: Formats;

  updateRules(rules: Rule[]): any;

  addToast(body: string, header: string): any;
}

interface ProjectRulesState {
  newRuleXpath: string;
  newRuleType: RuleTypes;
  newRuleValue: string;
  newRuleLoading: boolean;
  newRuleXpathValid: boolean;

  loading: boolean;
  rules: Rule[];
}

class ProjectRulesComponent extends React.Component<ProjectRulesProps, ProjectRulesState> {
  constructor(props: ProjectRulesProps) {
    super(props);
    this.state = {
      newRuleType: 'add',
      newRuleXpath: '',
      newRuleValue: '',
      newRuleLoading: false,
      newRuleXpathValid: true,

      loading: false,
      rules: props.rules
    }
    this.addRule = this.addRule.bind(this)
    this.getRules = this.getRules.bind(this)
    this.moveRule = this.moveRule.bind(this)
    this.handleChange = this.handleChange.bind(this);
    this.selectType = this.selectType.bind(this);
  }

  async addRule(order: 'start' | 'end') {
    this.setState({newRuleLoading: true})
    let rule = {
      type: this.state.newRuleType,
      order: 0,
      xpath: this.state.newRuleXpath,
      value: this.state.newRuleValue,
      toEnd: false
    };
    if (order === 'end') {
      rule['toEnd'] = true
    }
    let res = await createRule(this.props.projectId, rule);
    if (!res.ok) this.props.addToast(JSON.stringify(res), 'Ошибка при создании правила')
    if (res.ok) this.setState({
      newRuleType: 'add',
      newRuleXpath: '',
      newRuleValue: '',
      newRuleLoading: false,
      newRuleXpathValid: true,
    });
    this.setState({newRuleLoading: false})
    this.getRules();
  }

  async getRules() {
    this.setState({loading: true})
    let res = await getRules(this.props.projectId)
    if (!res.ok) this.props.addToast('', 'Ошибка при добавлении правила')
    else this.props.updateRules(res.result)
    this.setState({loading: false})
  }

  async moveRule(ruleId: string, direction: string) {
    let order = this.state.rules.map(rule => rule.id);
    let currentIndex = order.indexOf(ruleId);
    let newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
    if (newIndex < 0) {
      newIndex = 0
    }
    array_move(order, currentIndex, newIndex);

    let res = await editRuleOrder(this.props.projectId, order);
    if (!res.ok) this.props.addToast(JSON.stringify(res), 'RuleComponent move error')
    else this.getRules();
  }

  componentDidUpdate(prevProps: Readonly<ProjectRulesProps>, prevState: Readonly<ProjectRulesState>, snapshot?: any): void {
    if (prevProps.rules !== this.state.rules) this.setState({rules: this.props.rules})
  }

  handleChange(field: 'xpath' | 'value', value: string) {
    switch (field) {
      case 'xpath':
        this.setState({
          newRuleXpathValid:
            this.state.newRuleXpath.length > 0 &&
            value.length > 0 &&
            isXpathValid(value),
          newRuleXpath: value
        });
        break;
      case "value":
        this.setState({newRuleValue: value})
    }
  }

  selectType(event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) {
    event.preventDefault();

    // @ts-ignore
    this.setState({newRuleType: data.value});
  }

  render(): React.ReactNode {
    if (this.state.loading) {
      return (<div><p>Загрузка правил...</p><Spinner animation='grow' variant='dark'/></div>)
    }
    return <div>
      <Form className="my-4 border p-4 rounded">
        <Form.Group widths={'equal'} inline>
          <FormComponent
            placeholder='XPath'
            value={this.state.newRuleXpath}
            onChange={value => this.handleChange('xpath', value)}
          />
          <Form.Select
            fluid
            placeholder='Тип'
            options={types}
            value={this.state.newRuleType}
            onChange={this.selectType}/>
          <FormComponent
            placeholder='Значение'
            value={this.state.newRuleValue}
            onChange={value => this.handleChange('value', value)}
          />
          <DropdownButton variant='success' as={ButtonGroup}
                          disabled={this.state.newRuleLoading || !this.state.newRuleXpath}
                          title={<span>{this.state.newRuleLoading ?
                            <Spinner size="sm" animation="grow" variant="light"/> :
                            <FaPlus/>} Добавить правило</span>}>
            <Dropdown.Item onClick={() => this.addRule('start')}>Добавить в начало</Dropdown.Item>
            <Dropdown.Item onClick={() => this.addRule('end')}>Добавить в конец</Dropdown.Item>
          </DropdownButton>
        </Form.Group>
      </Form>
      {this.state.rules.map((rule, i) => <RuleComponent isFirst={i === 0} isLast={i + 1 === this.state.rules.length} order={i + 1} rule={rule} key={rule.id} format={this.props.format}
                                                   changeOrder={(direction: string) => this.moveRule(rule.id, direction)}
                                                             updateProject={() => this.getRules()}/>)}
    </div>
    // return <Table>
    //   <thead>
    //   <tr>
    //     <th>№п/п</th>
    //     <th>Селектор XPath</th>
    //     <th>Тип</th>
    //     <th>Значение</th>
    //     <th>Действия</th>
    //   </tr>
    //   </thead>
    //   <tbody>
    //   <tr>
    //     <td>Новое правило:</td>
    //
    //     <td><Form.Input fluid name='xpath' placeholder='XPath' value={this.state.newRuleXpath}
    //                     error={!this.state.newRuleXpathValid}
    //                     onChange={(e,data) => this.handleChange('xpath', data.value)}/></td>
    //     <td><Form.Select fluid placeholder='Тип' name='type' options={types} value={this.state.newRuleType}
    //                      onChange={this.selectType}/></td>
    //     <td><Form.Input fluid name='value' placeholder='Значение' value={this.state.newRuleValue}
    //                     onChange={(e,data) => this.handleChange('value', data.value)}/></td>
    //     <td>
    //       <DropdownButton variant='success' as={ButtonGroup}
    //                       disabled={this.state.newRuleLoading || !this.state.newRuleXpath}
    //                       title={<span>{this.state.newRuleLoading ?
    //                         <Spinner size="sm" animation="grow" variant="light"/> :
    //                         <FaPlus/>} Добавить правило</span>}>
    //         <Dropdown.Item onClick={() => this.addRule('start')}>Добавить в начало</Dropdown.Item>
    //         <Dropdown.Item onClick={() => this.addRule('end')}>Добавить в конец</Dropdown.Item>
    //       </DropdownButton>
    //     </td>
    //   </tr>
    //   {this.state.rules.map((rule, i) => <RuleComponent isFirst={i === 0} isLast={i + 1 === this.state.rules.length} order={i + 1} rule={rule} key={rule.id}
    //                                            changeOrder={(direction: string) => this.moveRule(rule.id, direction)}
    //                                            updateProject={() => this.getRules()}/>)}
    //   </tbody>
    // </Table>
  }
}

function mapStateToProps(state: AppStateRedux) {
  return {}
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    addToast: (body: string, header?: string) => dispatch(addToast(body, header))
  }
}

export const ProjectRules = compose(connect(mapStateToProps, mapDispatchToProps))(ProjectRulesComponent)
