import {compose, Dispatch} from "redux";
import {connect} from "react-redux";
import {AppStateRedux} from "../../store/types";
import React from "react";
import {addToast} from "../../store/toast/actions";
import {Controlled as CodeMirror} from 'react-codemirror2'

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/javascript-hint';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/hint/anyword-hint';
import 'codemirror/keymap/sublime';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/closetag';
import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/addon/fold/comment-fold';
import 'codemirror/addon/fold/foldgutter.css';
import 'codemirror/addon/hint/xml-hint';
import 'codemirror/mode/xml/xml';
import {tags} from "../../utils/tags";

import {default as ICodeMirror} from 'codemirror';
import {Button} from "semantic-ui-react";
import {FaSave} from "react-icons/all";
import {getStatic, setStatic} from "../../api/static";
import {LoadingSpinner} from "../Spinner/Spinner";

interface ProjectStaticProps {
  addToast(body: string, header?: string): void;

  updateStatic(xml: string | null): void;

  xml: string | null;
  projectId: string;
}

interface ProjectStaticState {
  loading: boolean;
  xml: string | null;
  editor: ICodeMirror.Editor | null;
  editorLoading: boolean;
}

class ProjectStaticComponent_ extends React.Component<ProjectStaticProps, ProjectStaticState> {
  constructor(props: ProjectStaticProps) {
    super(props);
    this.state = {
      loading: false,
      xml: props.xml,
      editor: null,
      editorLoading: true,
    }

    this.completeAfter = this.completeAfter.bind(this);
    this.completeIfAfterLt = this.completeIfAfterLt.bind(this);
    this.completeIfInTag = this.completeIfInTag.bind(this);
    this.updateStatic = this.updateStatic.bind(this);
    this.getStatic = this.getStatic.bind(this);
  }

  async getStatic() {
    this.setState({loading: true})
    let res = await getStatic(this.props.projectId);
    if (!res.ok) this.props.addToast(JSON.stringify(res), 'Ошибка!')
    else this.setState({xml: res.result !== null ? res.result.xml : ''})
    this.setState({loading: false})
  }

  async updateStatic() {
    this.setState({loading: true});

    let res = await setStatic(this.props.projectId, this.state.xml);
    if (!res.ok) this.props.addToast(JSON.stringify(res), 'Ошибка!');
    else this.getStatic();
    this.setState({loading: false});
  }

  completeAfter(cm: ICodeMirror.Editor, pred: any) {
    if (!pred || pred()) setTimeout(function () {
      if (!cm.state.completionActive)
        // @ts-ignore
        cm.showHint({completeSingle: false});
    }, 100);
    return ICodeMirror.Pass;
  }

  completeIfAfterLt(cm: ICodeMirror.Editor) {
    return this.completeAfter(cm, function () {
      let cur = cm.getCursor();
      return cm.getRange(ICodeMirror.Pos(cur.line, cur.ch - 1), cur) === "<";
    });
  }

  completeIfInTag(cm: ICodeMirror.Editor) {
    return this.completeAfter(cm, function () {
      let tok = cm.getTokenAt(cm.getCursor());
      if (tok.type === "string" && (!/['"]/.test(tok.string.charAt(tok.string.length - 1)) || tok.string.length === 1)) return false;
      let inner = ICodeMirror.innerMode(cm.getMode(), tok.state).state;
      return inner.tagName;
    });
  }

  render(): React.ReactNode {
    const extraKeys = {
      'Ctrl-Space': 'autocomplete',
      "'<'": (cm: ICodeMirror.Editor) => this.completeAfter(cm, null),
      "'/'": this.completeIfAfterLt,
      "' '": this.completeIfInTag,
      "'='": this.completeIfInTag,
    }
    return (<div className='p-3'>
      <Button onClick={() => this.updateStatic()} className='mb-2' loading={this.state.loading}
              disabled={this.state.loading}>
        <FaSave className='mr-2'/>Сохранить
      </Button>
      {this.state.editorLoading ? <LoadingSpinner text={'Загрузка редактора...'}/> : null}
      <CodeMirror
        value={this.state.xml ? this.state.xml : ''}
        options={{
          mode: 'xml',
          theme: 'material',
          lineNumbers: true,
          extraKeys: extraKeys,
          // @ts-ignore
          hintOptions: {schemaInfo: tags['yandex']}
        }}
        onChange={((editor, data, value) => {

        })}
        onBeforeChange={(editor, data, value) => {
          if (value.length > 0)
            this.setState({xml: value})
        }}
        editorDidMount={(editor => {
          this.setState({editorLoading: false})
          this.setState({editor})
        })}
      />

    </div>)
  }
}

function mapStateToProps(state: AppStateRedux) {
  return {}
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    addToast: (body: string, header?: string) => dispatch(addToast(body, header))
  }
}

export const ProjectStaticComponent = compose(connect(mapStateToProps, mapDispatchToProps))(ProjectStaticComponent_)
