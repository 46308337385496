import {Group, Project} from "../types";

export const SET_PROJECTS = 'PROJECTS_SET';

export function projectsSet(projects: Project[], count: number){
  return {
    type: SET_PROJECTS,
    projects, count
  }
}

export const TOGGLE_MODAL = 'TOGGLE_MODAL';

export function toggleAddProjectModal(show: boolean){
  return {
    type: TOGGLE_MODAL,
    show
  }
}

export const SET_GROUPS = 'SET_GROUPS';

export function groupsSet(groups: Group[]){
  return {
    type: SET_GROUPS,
    groups
  }
}
