import React from 'react';
import './style.css'

interface IBackButtonProps {
  onClick(): any;
}

export class BackButton extends React.Component<IBackButtonProps, any>{
  render(): React.ReactNode{
    return <a onClick={this.props.onClick} href="#" ><span className="back-button__text">Назад</span></a>
  }
}

