import {Formats} from "../utils";

export interface AppStateRedux {
  user: UserState;
  toasts: ToastState[];
  projects: ProjectState;
}

export interface User {
  username: string;
  id: string;
  admin: boolean;
}

export interface UserState {
  user?: User;
  isLogged: boolean;
}

export interface Action {
  [x: string]: any;

  type: string;
}

export interface ToastState {
  header?: string;
  body: string;
}

export interface ProjectHistory {
  id: string;
  projectId: string;
  errors: string[];
  status: number;
  reason: {
    data: {
      err: string;
      status_code: number;
    };
    type: string;
  };
  createdAt: string;
}

export interface ProjectStatic {
  id: string;
  xml: string | null;
  projectId: string;
}

export interface Group{
  id: string;
  title: string;
  projectsCount: number;
}

export interface Project {
  id: string;
  title: string;
  url: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  settings: ProjectSettings;
  history: ProjectHistory[];
  static: ProjectStatic;
  mirrors: Mirror[];
  rules: Rule[];
  groups: Group[];
}

export interface ProjectsMap {
  [id: string]: Project;
}

export interface GroupsMap {
  [id: string]: Group;
}

export interface ProjectsByGroup {
  [id: string]: Project[];
}

export interface ProjectSettings {
  format: Formats;
  statistic: string[];
  reverseRules: boolean
  outputFormat: Formats
}

export interface ProjectState {
  groupsMap: GroupsMap;
  groups: Group[];
  projects: Project[];
  projectsById: ProjectsMap;
  projectsByGroup: ProjectsByGroup;
  count: number;
  showAddModal: boolean;
}

export type RuleTypes = 'add' | 'delete' | 'edit' | 'append'

export interface Rule {
  id: string;
  projectId: string;
  type: RuleTypes;
  value: string;
  xpath: string;
  order: number;
}

export const types = [
  {value: 'add', text: 'Добавить'},
  {value: 'edit', text: 'Изменить'},
  {value: 'append', text: 'Добавить в конец'},
  {value: 'delete', text: 'Удалить'},
]

export interface Mirror{
  id: string;
  projectId: string;
  hash: string;
}

export interface Statistic {
  field: string;
  value: string;
  count: number;
  project: {
    id: string;
    title: string;
  }
}

export interface Rooms {
  '1': number,
  '2': number,
  '3': number,
  '4': number,
  'studio': number
}
