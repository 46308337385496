import {Action, User, UserState} from "../types";
import {LOGOUT, LOGIN, SET} from "./actions";
import Cookies from "js-cookie";

function login(state: UserState): UserState {
  return Object.assign({}, state, {isLogged: true});
}

function logout(): UserState {
  Cookies.remove('access-token')
  return { isLogged: false};
}

function setUser(state: UserState, user: User): UserState {
  return Object.assign({}, state, {user});
}

export function userReducer(state: UserState = {isLogged: false}, action: Action): UserState {
  switch (action.type) {
    case LOGIN:
      return login(state);
    case LOGOUT:
      return logout();
    case SET:
      return setUser(state, action.user);
    default:
      return state;
  }
}
