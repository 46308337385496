import {User} from "../types";

export const LOGIN = 'USER_LOGIN';

export function userLogin(){
  return {
    type: LOGIN
  }
}

export const SET = 'USER_SET';

export function userSet(user: User){
  return {
    type: SET,
    user
  }
}

export const LOGOUT = 'USER_LOGOUT';

export function userLogout() {
  return {
    type: LOGOUT
  }
}
