import {ApiResponse} from "./types";
import axios, {AxiosResponse} from 'axios';
import config from "../config";
import Cookies from "js-cookie";

interface AuthJWTResponse {
  access: string;
  refresh: string;
}

interface AuthLoginParams{
  username: string;
  password: string;
}

export async function login(data: AuthLoginParams): Promise<ApiResponse<AuthJWTResponse>>{
  try {
    let res = (await axios.post(config.api.baseUrl + '/auth/login', data)).data
    if(res.ok){
      axios.defaults.headers = {
        'Authorization': 'Bearer ' + res.result.access
      }
      Cookies.set('access-token', res.result.access);
    }
    return res
  } catch (e) {
    return e.response.data
  }
}

export async function register(data: AuthLoginParams): Promise<ApiResponse<AuthJWTResponse>>{
  try {
    let res = (await axios.post(config.api.baseUrl + '/auth/register', data)).data
    if(res.ok){
      axios.defaults.headers = {
        'Authorization': 'Bearer ' + res.result.access
      }
    }
    return res
  } catch (e) {
    return e.response.data
  }
}

interface GetMeResponse {
  id: string;
  username: string;
  admin: boolean;
}

export async function getMe(): Promise<ApiResponse<GetMeResponse>> {
  try {
    return (await axios.get(config.api.baseUrl + '/user/me')).data;
  } catch (e) {
    return e.response.data;
  }
}
