import React from "react";
import {Col, Row} from "react-bootstrap";
import {ButtonGroup, Form, InputOnChangeData, Button} from 'semantic-ui-react'
import './style.css'
import {LoadingSpinner} from "../../components/Spinner/Spinner";
import {login, register} from "../../api/auth";
import {connect} from "react-redux";
import {compose, Dispatch} from "redux";
import {addToast} from "../../store/toast/actions";
import errors from "../../api/errors";
// import {history} from "../../App";
import Cookies from "js-cookie";
import { withRouter } from "react-router";
import {userLogin} from "../../store/user/actions";

interface LoginPageState {
  loading: boolean;
  formData: {
    username: string;
    password: string;
  }
}

interface LoginPageProps {
  addToast(body: string, header?: string): void;
  userLogin(): void;
  history: any;
  match: any;
  location: any;
}

class LoginPageComponent extends React.Component<LoginPageProps, LoginPageState> {
  constructor(props:LoginPageProps) {
    super(props);
    this.state = {
      loading: false,
      formData: {
        username: '',
        password: ''
      }
    }
    this.handleChange = this.handleChange.bind(this);
    this.register = this.register.bind(this);
    this.login = this.login.bind(this);
  }

  componentDidMount(): void {
    // if (Cookies.get('access-token')) {
    //   // TODO: Get me and axios defaults
    //   this.props.userLogin();
    //   this.props.history.push('/')
    // }
  }


  handleChange(e: any, data: InputOnChangeData) {
    this.setState(Object.assign({}, this.state, {formData: Object.assign({}, this.state.formData, {[data.name]: data.value})}))
  }
  // @ts-ignore
  async register(e: MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    this.setState({loading: true})
    let res = await register(this.state.formData);
    if (!res.ok) this.props.addToast(errors(res.code))
    else {
      this.props.userLogin();
      this.props.history.push('/')
    }
    this.setState({loading: false})
  }
  // @ts-ignore
  async login(e: MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    this.setState({loading: true})
    let res = await login(this.state.formData);
    if (!res.ok) this.props.addToast(errors(res.code))
    else {
      this.props.userLogin();
      this.props.history.push('/')
    }
    this.setState({loading: false})
  }

  render(): React.ReactNode {
    return <div className="LoginPage__container container justify-content-center h-100 mt-5">
      {this.state.loading ? <LoadingSpinner text="Загрузка"/> :
        <Row className="row-eq-height align-items-center my-auto">
          <Col className="border p-3 mx-2">
            <Form>
              <Form.Input placeholder="Логин" name="username" value={this.state.formData.username}
                          onChange={this.handleChange}/>

              <Form.Input placeholder="Пароль" name="password" value={this.state.formData.password}
                          onChange={this.handleChange} type="password" minLength="8"/>
              <Form.Group inline>
                <ButtonGroup style={{'width': '100%'}}>
                  <Button floated="right" loading={this.state.loading} content="Войти" onClick={e => this.login(e)}/>
                  <Button floated="left" loading={this.state.loading} content="Зарегистрироваться" onClick={e => this.register(e)}/>
                </ButtonGroup>
              </Form.Group>
            </Form>
          </Col>
        </Row>}

    </div>
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    addToast: (body: string, header?: string) => dispatch(addToast(body, header)),
    userLogin: () => dispatch(userLogin())
  }
}

export const LoginPage = withRouter(compose(connect(() => {return {}}, mapDispatchToProps))(LoginPageComponent))
