import React from 'react';
import {Spinner} from "react-bootstrap";
import './style.css'

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps = {
  text: null as string | null,
  animation: 'grow' as 'grow' | 'border',
  variant: 'dark' as
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark'
}

export class LoadingSpinner extends React.Component<DefaultProps, any>{
  static defaultProps = defaultProps

  render(): React.ReactNode{
    return <div className="spinner__wrapper">
      <span>{this.props.text}</span>
      <Spinner animation={this.props.animation} variant={this.props.variant}/>
    </div>
  }
}

