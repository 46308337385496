import {ApiResponse} from "./types";
import {Project, ProjectSettings, Rule} from "../store/types";
import axios from 'axios';
import config from "../config";

interface GetAllProjects {
  count: number;
  projects: Project[];
}

export async function getAllProjects(limit: number = 10, offset: number = 0): Promise<ApiResponse<GetAllProjects>> {
  try {
    return (await axios.get(config.api.baseUrl + '/projects', {params: {limit, offset}})).data;
  } catch (e) {
    return e.response.data;
  }
}

interface CreateProjectPayload {
  title: string;
  url: string;
}

type CreateProjectResponse = Project;

export async function createProject(project: CreateProjectPayload): Promise<ApiResponse<CreateProjectResponse>> {
  try {
    return (await axios.post(config.api.baseUrl + '/project', project)).data;
  } catch (e) {
    return e.response.data;
  }
}

export async function getProjectById(projectId: string): Promise<ApiResponse<Project>> {
  try {
    return (await axios.get(config.api.baseUrl + '/project/' + projectId)).data;
  } catch (e) {
    return e.response.data;
  }
}

export async function generateProject(projectId: string): Promise<ApiResponse<any>> {
  try {
    return (await axios.post(config.api.baseUrl + '/project/' + projectId + '/generate')).data;
  } catch (e) {
    return e.response.data;
  }
}

interface UpdateProjectPayload {
  title: string;
  url: string;
  settings: ProjectSettings
}

export async function updateProject(projectId: string, data: UpdateProjectPayload) {
  try {
    return (await axios.put(config.api.baseUrl + '/project/' + projectId, data)).data;
  } catch (e) {
    return e.response.data;
  }
}

export async function deleteProject(projectId: string): Promise<ApiResponse<any>> {
  try {
    return (await axios.delete(config.api.baseUrl + '/project/' + projectId)).data;
  } catch (e) {
    return e.response.data;
  }
}

export async function duplicateProject(projectId: string){
  try {
    return (await axios.post(config.api.baseUrl + '/project/' + projectId + '/duplicate')).data;
  } catch (e) {
    return e.response.data;
  }
}
