import React from "react";
import {ProjectHistory as History} from "../../store/types";
import {Table} from "react-bootstrap";

interface ProjectHistoryProps {
  history: History[]
}

export class ProjectHistoryComponent extends React.Component<ProjectHistoryProps, {}>{
  render(): React.ReactNode {
    return <Table>
      <thead>
        <tr>
          <th>Дата</th>
          <th>Статус</th>
          <th>Ошибка</th>
        </tr>
      </thead>
      <tbody>
      {this.props.history.map(h => <tr key={h.id}>
        <td>{h.createdAt}</td>
        <td>{h.status}</td>
        <td>{h.reason.data ? h.reason.data.err : null}</td>
      </tr>)}
      </tbody>
    </Table>
  }
}
